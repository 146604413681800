<div style="position:relative;top:-1.5px;left:370px;width:320px;z-index:1000;">
  <button type="button" (click)="onClick()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" style="
min-height: 0px;font-size: 12px;padding: 2px;">
    <span class="uitk-icon uitk-icon-process sm-icon"></span> Promote Job
  </button>
</div>
<Form [formGroup]="promoteForm">
  <div #mainView style="position:relative;top:-26px;">
    <h5 style="margin:0px;padding:0px;">
      Promote
    </h5>
    <hr class="line1" />

    <div class="uitk-l-grid">
      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col" style="padding-top: 0px;padding-left: 0px;">
          <uitk-form-field>
            <label uitkLabel style="font-size: 12px;padding-right: 15px;margin: 0px;">
              Destination Environment
            </label> 
            <uitk-dropdown uitkId="singleselect-template"
                           formControlName="environment"
                           [items]="environments"
                           [enableSelectFlag]="true"
                           name="template-example"
                           style="width: 60%;">
            </uitk-dropdown>
            <uitk-form-field-error *ngIf="promoteForm?.controls?.environment?.errors?.required == 'true'">Required</uitk-form-field-error>
          </uitk-form-field>
        </div>
      </div>

      <div class="uitk-l-grid__row" style="margin:0px;padding:0px;position:relative;top:-20px;">
        <div class="uitk-l-grid__col" style="margin:0px;padding:0px;width:100%;">
          <div style="position: relative; top: -1.5px; right:-1px; width: 100px;;">
            <label uitkLabel style="padding-right: 3px;">Response</label>
            <uitk-form-field>
              <textarea #viewResponse uitkTextarea
                        formControlName="responseData"
                        uitkId="Responsetextarea"
                        aria-describedby="my-required-reactive-textarea-ht"
                        style="padding: 0px;height:20px;min-height:20px;width:100%;position:relative;top:-10px;resize:vertical !important"
                        (resize)="resizeEvent()"></textarea>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div #viewDiv (window:resize)="onResize($event)" style="position:relative;top:-22px; ">
        <ag-grid-angular #agGrid
                         id="myGrid"
                         style="height:100%;"
                         class="ag-theme-alpine"
                         [defaultColDef]="defaultColDef"
                         [rowData]="rowData"
                         [columnDefs]="columnDefs"
                         (cellClicked)="onCellClicked($event)"
                         (selectionChanged)="onSelectionChanged($event)"
                         rowSelection="multiple"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</Form>
