// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { JobAlertsComponent } from 'src/app/modules/job-alerts/job-alerts.component';


@Component({
  selector: 'app-button-renderer',
  template: `
    <button type="button" (click)="onClick($event)" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
      <span class="uitk-icon uitk-icon-edit sm-icon" ></span> Select</button>
<!--
    <button type="button" (click)="onClick($event)" class="btn btn-hover">
      <span class="uitk-icon uitk-icon-close sm-icon"></span>
    </button>

  <button [disabled]="!canDeleteJob" type="button" (click)="Delete()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
    <span class="uitk-icon uitk-icon-trash_delete sm-icon"></span> Delete
  </button>
-->
  `,
  styles: [
        `
    button {
      padding: 2px 2px 2px 2px !important;
      min-height: 0px !important;
      font-size: 12px;
      //background-color: #cc7109; /* #c25608;*/
    }

   .btn {
      line-height: 18px;
      height:20px;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      border: 0px;
      background-color: transparent;
      position:relative;
      text-align:center;
      top:-2px;
    }
    .btn-hover:hover {
      border: 2px solid #C25608;
      border-radius: 4px;
      text-align: center;
    }`
  ]
})

export class ListButtonRendererComponent implements ICellRendererAngularComp {

  //buttonDisable: boolean = true;
  constructor(
    private jobSummaryService: JobSummaryService,
    private jobAlertComponent:JobAlertsComponent
  ) {
  }
  params: any;
  agInit(params: any): void {
    this.params = params;
  }

  //ngOnInit() {    
  //}


  refresh(params?: any): boolean {
    return true;
  }

  onClick($event: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);
      // this.jobAlertComponent.loadGrid();

    }
  }
}
