import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Mode } from '../enums/mode.enum';
import { JobSummaryDto } from '../models/JobSummaryDto.model';
import { ModeService } from './mode.service';
import { ConfigService } from 'src/app/shared/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class JobSummaryService {
  //Promote
  public promoteSystemNameList$: any = new BehaviorSubject(null); //To load the promotion form

  public deletePromoteList: boolean = false; 
  public deletePromoteList$:any = new BehaviorSubject(null);
    
  //JobList
  public jobListSelected$: any = new BehaviorSubject(null);   //This event is fired when the user clicks and selects a Job-list intem.
  public jobListClear$: any = new BehaviorSubject(null); //This is called to clear the Job-Listed selected row
  public selectedRowId: any; //This holds the selected row Id from the Job-list
  public selectedRowData: any; //Holds the selected Job Data
  public jobListRefresh$: any= new BehaviorSubject(null); //To refresh the job list 

  //Schedule
  public scheduleValidate$: any = new BehaviorSubject(null);   //Schedule is listening for scheduleValidate$ to check for form validation.  Also a call to this event will populate the Json object
  public scheduleValid: boolean = false; //This identifies if the schedule is valid.  Must be accessed directly after the above event.
  public scheduleClearData$: any = new BehaviorSubject(null);   //The scheduleClearData$ event will set the form to empty for a new record
  public scheduleDirty$: any = new BehaviorSubject(null);   //The scheduleClearData$ event will check the form for dirty data
  public scheduleDirty: boolean = false; //This identifies if the schedule data is dirty or not.
  public schduleData: any; //The data from the schedule form after validate is called

  //Config
  public configValid: boolean = false; 
  public configDirty: boolean = false;
  public configDirty$:any  = new BehaviorSubject(null); 
  public jobConfigFormActive: boolean = false; //This is set to tre if the Job-Configuration form is active
  public configClearData$: any = new BehaviorSubject(null);

  //Alert
  public alertDirty$:any  = new BehaviorSubject(null); 
  public alertClearData$: any = new BehaviorSubject(null);
  public isEnableDeleteButton$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(true);
  public alertDirty: boolean = false;
  public selectedOwnerID: any;
  //Home
  public homeSetHistoryView$: any = new BehaviorSubject(null); 

  public reloadRecord$: any = new BehaviorSubject(null); 
  public reloadRecord: boolean = false;

  //private jobSummaryDtos: JobSummaryDto[] | null = null; 
  //gotJobSummariesEmitter = new Subject<JobSummaryDto[]>();

  //constructor(
  //  private http: HttpClient,
  //  private modeService: ModeService,
  //  private configService: ConfigService,  ) {
  //}

  //getJobSummaries(errorMode: Mode) {
  //  if (this.jobSummaryDtos) {
  //    this.modeService.mode = Mode.View;
  //    this.gotJobSummariesEmitter.next(this.jobSummaryDtos);
  //    return;
  //  }

  //  this.modeService.mode = Mode.Wait;

  //  this.http.get<JobSummaryDto[]>(this.configService.apiUrl+'/Scheduler/GetJobSummaries')
  //    .subscribe(returnData => {
  //      this.jobSummaryDtos = returnData.slice();
  //      this.modeService.mode = Mode.View;
  //      this.gotJobSummariesEmitter.next(returnData.slice());
  //    }, error => {
  //      this.modeService.mode = errorMode;
  //      console.log(error);
  //      alert('An error occurred while getting jobs.  Please try again');
  //    });
  //}

  //clearJobSummaries() {
  //  this.jobSummaryDtos = [];
  //}
}
