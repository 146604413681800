import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/shared/services/config.service';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { ModalDialogService } from 'src/app/modules/modaldialogbox/modal-dialog.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent {
  isLoaded: boolean = false;
  //Security
  canViewJobListing: boolean = false;
  canViewJob: boolean = false;
  canViewHistory: boolean = false;
  canPromote: boolean = false;
  canViewAlerts:boolean=false;

  viewJobConfig: boolean = false;
  viewJobHistory: boolean = false;
  viewJobPromotion: boolean = false;
  viewJobAlerts: boolean= false;
  viewJobListing: boolean = false;
  formdata: any;
  oldformdata: any;
  jobId: any;
  public OwnerArray: any = [];
  environment: any = "Unknown";

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private configService: ConfigService,
    protected httpClient: HttpClient,
    public modalDialogService: ModalDialogService,
    private jobSummaryService: JobSummaryService
  ) {

  }
  async ngOnInit() {
    this.jobSummaryService.jobListSelected$.subscribe((x: any) => {
      this.jobId = this.jobSummaryService.selectedRowId == null ? '' : '    Id(' + this.jobSummaryService.selectedRowId.toString() +')';
    });

    var homeSetHistoryViewFirstTime: boolean = true;
    this.jobSummaryService.homeSetHistoryView$.subscribe((x: any) => {
      if (homeSetHistoryViewFirstTime == false) {
        homeSetHistoryViewFirstTime = true;
      } else {
        this.ShowHistory();
      }
    });


    await this.authorizationService.Authorize();
    this.canViewJobListing = this.authorizationService.Authorized('viewjoblisting')
    this.canViewJob = this.authorizationService.Authorized('viewjob')
    this.canViewHistory = this.authorizationService.Authorized('viewhistory')
    this.canPromote = this.authorizationService.Authorized('promotejobs')
    this.canViewAlerts=this.authorizationService.Authorized('viewalerts')
    this.environment = this.authorizationService.environment;

    if (this.canViewJob == false && this.canViewHistory == true) this.ShowHistory();
    if (this.canViewJob == false && this.canViewHistory == false && this.canPromote) this.ShowPromotion();
    if (this.canViewJob == true) this.ShowConfig();
    if ( this.canViewAlerts== true) this.ShowAlerts();

    this.environment = this.configService.environment;

    this.isLoaded = true;
    //Example authorixation calls
    //console.log('App MsId: ' + this.authorizationService.msId);
    //console.log('App Owners: ' + this.authorizationService.owners);
    //console.log('App has viewJobListing access: ' + this.authorizationService.Authorized('viewjoblisting'));
  }

  ShowConfig() {
    this.viewJobHistory = false;
    this.viewJobConfig = true;
    this.viewJobPromotion = false;
    this.viewJobAlerts= false;
  }

  ShowHistory() {
    if (this.jobSummaryService.jobConfigFormActive) {
      this.jobSummaryService.scheduleDirty$.next();
      this.jobSummaryService.configDirty$.next();
      if (this.jobSummaryService.scheduleDirty || this.jobSummaryService.configDirty) {
        this.modalDialogService.confirm('Dirty Data on the form', 'Do you want to discard the changes?').then((state: any) => {
          if (state == true) {
            this.jobSummaryService.scheduleClearData$.next();
            this.setHistoryView();
          }
        });
      } else {
        this.setHistoryView();
      }
    } else {
      this.setHistoryView();
    }
  }

  setHistoryView() {
    this.viewJobHistory = true;
    this.viewJobConfig = false;
    this.viewJobPromotion = false;
    this.viewJobAlerts= false;
  }

  ShowPromotion() {
    if (this.jobSummaryService.jobConfigFormActive) {
      this.jobSummaryService.scheduleDirty$.next();
      this.jobSummaryService.configDirty$.next();
      if (this.jobSummaryService.scheduleDirty || this.jobSummaryService.configDirty) {
        this.modalDialogService.confirm('Dirty Data on the form', 'Do you want to discard the changes?').then((state: any) => {
          if (state == true) {
            this.setPromotionView();
            this.jobSummaryService.scheduleDirty = false;
          }
        });
      } else {
        this.setPromotionView();
      }
    } else {
      this.setPromotionView();
    }
  }

  setPromotionView() {
    this.viewJobHistory = false;
    this.viewJobConfig = false;
    this.viewJobPromotion = true;
    this.viewJobAlerts = false;
  }
  ShowAlerts(){
    if (this.jobSummaryService.jobConfigFormActive) {
      this.jobSummaryService.scheduleDirty$.next();
      this.jobSummaryService.configDirty$.next();
      if (this.jobSummaryService.scheduleDirty || this.jobSummaryService.configDirty) {
        this.modalDialogService.confirm('Dirty Data on the form', 'Do you want to discard the changes?').then((state: any) => {
          if (state == true) {
            this.setAlertsView();
            this.jobSummaryService.scheduleDirty = false;
          }
        });
      } else {
        this.setAlertsView();
      }
    } else {
      this.setAlertsView();
    }
  }
  setAlertsView(){
    this.viewJobHistory = false;
    this.viewJobConfig = false;
    this.viewJobPromotion = false;
    this.viewJobAlerts= true;
  }
  Help() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/help'])
    );
    window.open(url, '_blank');
  }
}
