import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/shared/services/config.service';

@Injectable({
  providedIn: 'root',
})

export class AuthorizationService {
  public resources: any;
  public owners: any;
  public msId: any = 'unknown';
  public environment: any;

  public Owners: any = "";
  public OwnerArray: any = [];
  public OwnerSelect: any = [];

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  public async Authorize() {

    await this.httpClient.get(this.configService.configRef ?.apiUrl_Auth as string, { responseType: 'text', withCredentials: true }).toPromise().then((user: any) => {
      this.msId = user;
    }, (error: any) => {
      console.log(error);
      alert('Unable to determine user.  Error:' + error.message);
    });

    const user = { 'user': this.msId };
    await this.httpClient.post(this.configService.apiUrl + '/Authorization/GetAuthorization', user)
      .toPromise().then((returnData: any) => {

        console.log(returnData);
        if (returnData.ResultStatus != 'Success') {
          alert('An error occured in retrieving the Authorization for User[' + returnData.msId + ']. \n\n'
            + 'Error:\n' + returnData.ResultMessage
          );
          throw new Error(returnData.ResultMessage);
        }
        this.resources = returnData.Resources;
        this.owners = returnData.Owners;
        this.msId = returnData.msId;
        this.environment = returnData.Environment;

        console.log(this.owners);
        this.resources.forEach((element: any) => {
          if (element.indexOf("owner.") != -1) {
            this.OwnerArray.push(element);
            if (this.Owners != "")
              this.Owners += "," + element;
            else
              this.Owners += element;
          }
        });

        this.OwnerArray = this.OwnerArray.sort((n1: any, n2: any) => {
          if (n1.toUpperCase() > n2.toUpperCase()) return 1;
          if (n1.toUpperCase() < n2.toUpperCase()) return -1;
          return 0;
        });

        var oiItem = this.OwnerArray.findIndex((x: any) => x == "owner.oi");
        var newList = [];

        if (oiItem != 0) {
          newList.push("owner.oi");
          for (var i = 0; i < this.OwnerArray.length; i++) {
            if (this.OwnerArray[i] != "owner.oi") newList.push(this.OwnerArray[i]);
          }
          this.OwnerArray = newList;
        }
  

        this.OwnerArray.forEach((element: any) => {
          this.OwnerSelect.push(element.replace("owner.", ""));
        });

      }, (error: any) => {
        console.log(error);
        alert('An error occured in retrieving the Authorization for this user.  Error:' + error.message);
      });
  }

  public Authorized(resource: string): boolean {
    if (this.resources == undefined) return false;
    if (this.resources.indexOf(resource) != -1)
      return true;
    else
      return false;
  }
}

