import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../models/config.model';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  environment = '';
  apiUrl = '';
  configRef: Config | undefined;

  constructor(
    private http: HttpClient,
  ) {
  }


   
  async loadConfigurations() {
    await this.http.get<{ environment: string }>('assets/config/environment.json').toPromise().then((response: { environment: string }) => {
      this.environment = response.environment;
      let environments = ['Local', 'Development', 'Test', 'Staging', 'Production'];

      if (!environments.some(e => e === response.environment)) {
        console.log("Invalid environment file is setup.");
        return;
      };
    });

    await this.http.get<Config>(`assets/config/appsettings.${this.environment}.json`).toPromise().then((result: Config) => {
      this.configRef = result;
      switch (this.environment) {
        case 'Local':
          this.apiUrl = result.apiUrl_Local;
          break;
        case 'Development':
          this.apiUrl = result.apiUrl_Development;
          break;
        case 'Test':
          this.apiUrl = result.apiUrl_Test;
          break;
        case 'Staging':
          this.apiUrl = result.apiUrl_Staging;
          break;
        case 'Production':
          this.apiUrl = result.apiUrl_Production;
          break;

        default:
          this.apiUrl = "";

      }


    });
  }
}
