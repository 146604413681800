<div style="position:relative;top:-50px;min-width:600px;">
  <div>
    <h5 style="margin:0px;padding:0px;">
      Schedule
    </h5>
  </div>
  <hr class="line1" />
  <div class="uitk-l-grid">
    <form [formGroup]="ScheduleForm">
      <!--Row 1-->
      <div class="uitk-l-grid__row uitk-u-flex__align--center-x uitk-l-grid__row--no-gutter" style="padding-bottom:4px;">

        <!--OccursEvery Option-->
        <div class="uitk-l-grid__col--3">
          <div style="min-width: 0px;padding-right:10px;">
            <span style="white-space:nowrap;">
              <uitk-radio-group uitkId="my-basic-radio-group" formControlName="OccursEveryRadio" [itemList]="OccursEveryRadioitems"
                                (change)="onOccursRadio($event)" name="OccursEveryRadio">
              </uitk-radio-group>
            </span>
          </div>
        </div>

        <!--Occures Every Value-->
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <input [attr.disabled]="disableOccursEveryControls ? '' : null" id="OccursEveryInput2" style="width: 50px;" uitkInput formControlName="OccursEveryInput">
            <!--<uitk-form-field-error *ngIf="ScheduleForm?.controls?.OccursEveryInput?.invalid">Invalid</uitk-form-field-error>-->
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.OccursEveryInput?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.OccursEveryInput?.invalid
                                   && ScheduleForm?.controls?.OccursEveryInput?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
      </div>

      <!--Row1.5-->
      <div class="uitk-l-grid__row uitk-u-flex__align--center-x uitk-l-grid__row--no-gutter"  *ngIf="disableOccursEveryControls==false">
        <div class="uitk-l-grid__col" style="padding-right:10px;">
        </div>
        <!--Interval-->
        <div class="uitk-l-grid__col" style="padding-right:10px;">
          <uitk-form-field>
            <label uitkLabel style="position:relative;top:5px;padding-right:5px;">
              Interval
            </label>
            <uitk-dropdown [disabled]="disableOccursEveryControls" uitkId="singleSelect-template" [items]="intervels"
                           [enableSelectFlag]="true" formControlName="intervel" style="width:120px;"></uitk-dropdown>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.intervel?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.intervel?.invalid
                                   && ScheduleForm?.controls?.intervel?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>

        <!--Start Time-->
        <div class="uitk-l-grid__col" style="padding-right:10px;">
          <label style="padding-top:2px;">
            <span style="white-space:nowrap;padding-right:5px;padding-top:2px;">Start Time</span>
            <div *ngIf="ScheduleForm?.controls?.OccursEverystartTime?.errors?.required == 'true'"
                 style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
              <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
              <span style="position:relative;top:2px;left:5px;">
                Required
              </span>
            </div>
            <div *ngIf="ScheduleForm?.controls?.OccursEverystartTime?.invalid && ScheduleForm?.controls?.OccursEverystartTime?.errors?.required != 'true'"
                 style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
              <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
              <span style="position:relative;top:2px;left:5px;">
                Invalid
              </span>
            </div>
            <input [readonly]="disableOccursEveryControls" [attr.disabled]="disableOccursEveryControls ? '' : null"
                   formControlName="OccursEverystartTime" class="datetime datetime-hover datetime-select"
                   [owlDateTimeTrigger]="OccursEverystartTime" [owlDateTime]="OccursEverystartTime" style="width: 80px;">
            <owl-date-time [disabled]="disableOccursEveryControls" [pickerType]="'timer'" [hour12Timer]="true" #OccursEverystartTime></owl-date-time>
          </label>
        </div>

        <!--End Time-->
        <div class="uitk-l-grid__col">
          <label style="padding-top:2px;">
            <span style="white-space:nowrap;padding-right:5px;padding-top:2px;">End Time</span>
            <div *ngIf="ScheduleForm?.controls?.OccursEveryendTime?.errors?.required == 'true'"
                 style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
              <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
              <span style="position:relative;top:2px;left:5px;">
                Required
              </span>
            </div>
            <div *ngIf="ScheduleForm?.controls?.OccursEveryendTime?.invalid && ScheduleForm?.controls?.OccursEveryendTime?.errors?.required != 'true'"
                 style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
              <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
              <span style="position:relative;top:2px;left:5px;">
                Invalid
              </span>
            </div>
            <input [readonly]="disableOccursEveryControls" [attr.disabled]="disableOccursEveryControls ? '' : null"
                   formControlName="OccursEveryendTime" class="datetime datetime-hover datetime-select"
                   [owlDateTimeTrigger]="OccursEveryendTime" [owlDateTime]="OccursEveryendTime" style="width: 80px;">
            <owl-date-time [disabled]="disableOccursEveryControls" [pickerType]="'timer'" [hour12Timer]="true" #OccursEveryendTime></owl-date-time>
          </label>
        </div>
        <div class="uitk-l-grid__col" style="padding-right:10px;">
        </div>
      </div>

      <!--Row 2-->
      <div class="uitk-l-grid__row uitk-u-flex__align--center-x uitk-l-grid__row--no-gutter">
        <!--OccursAtInputStartTime Option-->
        <div class="uitk-l-grid__col--2">
          <div style="min-width: 0px;padding-right:10px;">
            <span style="white-space:nowrap;">
              <uitk-radio-group uitkId="OccursAtRadioId" formControlName="OccursAtRadio" [itemList]="OccursAtRadioitems"
                                (change)="onOccursRadio($event)" name="OccursAtRadio">
              </uitk-radio-group>
            </span>
          </div>
        </div>
        <!--OccursAtInputStartTime Value-->
        <div class="uitk-l-grid__col">
          <label style="padding-top:2px;width:200px;">
            <div *ngIf="ScheduleForm?.controls?.OccursAtInputStartTime?.errors?.required == 'true'"
                 style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
              <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
              <span style="position:relative;top:2px;left:5px;">
                Required
              </span>
            </div>
            <div *ngIf="ScheduleForm?.controls?.OccursAtInputStartTime?.invalid && ScheduleForm?.controls?.OccursAtInputStartTime?.errors?.required != 'true'"
                 style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
              <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
              <span style="position:relative;top:2px;left:5px;">
                Invalid
              </span>
            </div>
            <input [readonly]="disableOccursAtInputStartTime" [attr.disabled]="disableOccursAtInputStartTime ? '' : null"
                   formControlName="OccursAtInputStartTime" class="datetime datetime-hover datetime-select"
                   [owlDateTimeTrigger]="OccursAtInputStartTime" [owlDateTime]="OccursAtInputStartTime" style="width: 80px;">
            <owl-date-time [disabled]="disableOccursAtInputStartTime" [pickerType]="'timer'" [hour12Timer]="true" #OccursAtInputStartTime></owl-date-time>
          </label>
        </div>
      </div>

      <div style="padding-top:4px;">
        <hr class="line1" />
      </div>

      <!--Row 3 Daily-->
      <div class="uitk-l-grid__row uitk-u-flex__align--left uitk-l-grid__row--no-gutter">
        <div class="uitk-l-grid__col">
          <uitk-radio-group #viewDailyRadio uitkId="OccursDailyRadioId" formControlName="OccursDailyRadio" [itemList]="OccursDailyRadioitems"
                            (change)="onschedRadio($event)" name="OccursDailyRadio">
          </uitk-radio-group>
        </div>
        <div class="uitk-l-grid__col">
          <span style="white-space:nowrap;">
            <uitk-form-field>
              <label style="padding-top:5px;padding-right: 5px" uitkLabel>Run every</label>
              <input [attr.disabled]="disableOccursDailyRadioControls ? '' : null" style="width: 35px; padding-right: 5px; "
                     id="OccursEveryInput6" uitkInput formControlName="OccursDailyInputDays">
              <uitk-form-field-error *ngIf="ScheduleForm?.controls?.OccursDailyInputDays?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="ScheduleForm?.controls?.OccursDailyInputDays?.invalid
                                     && ScheduleForm?.controls?.OccursDailyInputDays?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </span>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label style="padding-top:5px;padding-right: 5px;padding-left: 5px" uitkLabel>Day(s)</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
        </div>
        <div class="uitk-l-grid__col">
        </div>
        <div class="uitk-l-grid__col">
        </div>
      </div>

      <!--Row 4 Weekly-->
      <div class="uitk-l-grid__row uitk-u-flex__align--left uitk-l-grid__row--no-gutter">
        <div class="uitk-l-grid__col">
          <uitk-radio-group uitkId="OccursWeeklyRadioId" formControlName="OccursWeeklyRadio" [itemList]="OccursWeeklyRadioitems"
                            (change)="onschedRadio($event)" name="OccursWeeklyRadio">
          </uitk-radio-group>
        </div>
        <div class="uitk-l-grid__col">
          <span style="white-space:nowrap;">
            <uitk-form-field>
              <label style="padding-top:5px;padding-right: 5px" uitkLabel>Run every</label>
              <input [attr.disabled]="disableOccursWeeklyRadioControls ? '' : null" style="width: 35px; padding-right: 5px; "
                     id="RunEveryInputWeeks" uitkInput formControlName="RunEveryInputWeeks">
              <uitk-form-field-error *ngIf="ScheduleForm?.controls?.RunEveryInputWeeks?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="ScheduleForm?.controls?.RunEveryInputWeeks?.invalid
                                     && ScheduleForm?.controls?.RunEveryInputWeeks?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </span>
        </div>

        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label style="padding-top: 5px;padding-left: 5px;" uitkLabel>Week(s)</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
        </div>
        <div class="uitk-l-grid__col">
        </div>
        <div class="uitk-l-grid__col">
        </div>
      </div>

      <!--Row 5 weekdays-->
      <div class="uitk-l-grid__row uitk-l-grid__row--no-gutter" *ngIf="disableOccursWeeklyRadioControls==false">
        <span style="width:40px;"></span>
        <div class="uitk-l-grid__col">
          <div *ngFor="let scheduleType of scheduleTypes;let i=index" style="float:left;">
            <uitk-form-field>
              <input id="my-group-reactive-checkbox{{i}}" formControlName="OccursWeeklyCheckBox{{scheduleType.name}}"
                     type="checkbox" [attr.disabled]="disableOccursWeeklyRadioControls ? '' : null" uitkCheckbox />
              <label style="" uitkCheckboxLabel for="my-group-reactive-checkbox{{i}}">{{scheduleType.name}}</label>
            </uitk-form-field>
          </div>
        </div>
      </div>

      <!--Row 6 Monthly-->
      <div class="uitk-l-grid__row uitk-u-flex__align--left uitk-l-grid__row--no-gutter">
        <div class="uitk-l-grid__col">
          <uitk-radio-group uitkId="OccursMonthlyRadioId" formControlName="OccursMonthlyRadio" [itemList]="OccursMonthlyRadioitems"
                            (change)="onschedRadio($event)" name="OccursMonthlyRadio">
          </uitk-radio-group>
        </div>

        <div class="uitk-l-grid__col">
          <span style="white-space:nowrap;">
            <uitk-form-field>
              <label style="padding-top:5px;padding-right: 5px" uitkLabel>Run every</label>
              <input [attr.disabled]="disableOccursMonthlyRadioControls ? '' : null" style="width: 35px; padding-right: 5px; "
                     id="RunEveryInputMonths" uitkInput formControlName="RunEveryInputMonths">
              <uitk-form-field-error *ngIf="ScheduleForm?.controls?.RunEveryInputMonths?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="ScheduleForm?.controls?.RunEveryInputMonths?.invalid
                                     && ScheduleForm?.controls?.RunEveryInputMonths?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </span>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label uitkLabel style="padding-top: 5px;padding-left: 5px;">Month(s)</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
        </div>
        <div class="uitk-l-grid__col">
        </div>
        <div class="uitk-l-grid__col">
        </div>
      </div>

      <!--Row 7-->
      <div class="uitk-l-grid__row uitk-u-flex__align--left uitk-l-grid__row--no-gutter" *ngIf="disableOccursMonthlyRadioControls==false">
        <span style="width:40px;"></span>
        <div class="uitk-l-grid__col">
          <uitk-radio-group uitkId="RunOnTheRadioId" formControlName="RunOnTheRadio"
                            [itemList]="RunOnTheRadioitems" (change)="onRunRadio($event)" name="RunOnTheRadio">
          </uitk-radio-group>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <uitk-dropdown *ngIf="disableRunOnTheRadioControls==false" uitkId="singleSelect-template" [items]="MonthlyFirstintervels"
                           [enableSelectFlag]="true" formControlName="MonthlyFirstintervel" style="width:120px;"></uitk-dropdown>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.MonthlyFirstintervel?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.MonthlyFirstintervel?.invalid
                                   && ScheduleForm?.controls?.MonthlyFirstintervel?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <uitk-dropdown *ngIf="disableRunOnTheRadioControls==false" uitkId="singleSelect-template" [items]="MonthlySecondintervels"
                           [enableSelectFlag]="true" formControlName="MonthlySecondintervel" style="width:140px;"></uitk-dropdown>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.MonthlySecondintervel?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.MonthlySecondintervel?.invalid
                                   && ScheduleForm?.controls?.MonthlySecondintervel?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label *ngIf="disableRunOnTheRadioControls==false" uitkLabel style="padding-top: 5px;padding-left: 5px;">of the Month</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
        </div>
      </div>

      <!--Row 8-->
      <div style="height:4px;"></div>
      <div class="uitk-l-grid__row uitk-u-flex__align--left uitk-l-grid__row--no-gutter" *ngIf="disableOccursMonthlyRadioControls==false">
        <span style="width:40px;"></span>
        <div class="uitk-l-grid__col">
          <uitk-radio-group uitkId="RunOnThe2RadioId" formControlName="RunOnThe2Radio"
                            [itemList]="RunOnThe2Radioitems" (change)="onRunRadio($event)" name="RunOnThe2Radio">
          </uitk-radio-group>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <input [attr.disabled]="disableRunOnThe2RadioControls ? '' : null" style="width: 35px; padding-right: 5px;"
                   id="RunOnTheInputDayOfTheMonths" uitkInput formControlName="RunOnTheInputDayOfTheMonths">
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.RunOnTheInputDayOfTheMonths?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="ScheduleForm?.controls?.RunOnTheInputDayOfTheMonths?.invalid
                                   && ScheduleForm?.controls?.RunOnTheInputDayOfTheMonths?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label style="padding-top: 5px;padding-left: 5px;" uitkLabel>Day of the Month</label>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
        </div>
        <div class="uitk-l-grid__col">
        </div>
      </div>

      <div style="padding-top:4px;">
        <hr class="line1" />
      </div>

      <!--Row 9-->
      <div class="uitk-l-grid__row uitk-l-grid__row--no-gutter">

        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <input (change)="onClickNoEndDateCheckBox($event)" id="my-group-reactive-checkbox" formControlName="NoEndDateCheckBox" type="checkbox" uitkCheckbox />
            <label uitkCheckboxLabel for="my-group-reactive-checkbox">No End Date</label>
          </uitk-form-field>
        </div>

        <div class="uitk-l-grid__col">
          <label style="padding-top:2px;" *ngIf="disableEndDateInput==false">
            <span style="white-space:nowrap;padding-right:5px;">End Date</span>
            <div *ngIf="(ScheduleForm?.controls?.EndDateInput?.errors?.required && ScheduleForm?.controls?.EndDateInput?.touched) || ScheduleForm?.controls?.EndDateInput?.valid==false"
                 style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
              <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
              <span style="position:relative;top:2px;left:5px;">
                Invalid
              </span>
            </div>
            <input (ngModelChange)="onClickEndDateInput($event)" 
                   formControlName="EndDateInput" class="datetime datetime-hover datetime-select"
                   [owlDateTimeTrigger]="EndDateInput" [owlDateTime]="EndDateInput" style="width: 80px;">
            <owl-date-time [disabled]="disableEndDateInput" [pickerType]="'calendar'" [hour12Timer]="true" #EndDateInput></owl-date-time>
          </label>
        </div>

        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <input id="my-group-reactive-checkbox-Enabled" formControlName="EnabledCheckBox" type="checkbox" uitkCheckbox />
            <label uitkCheckboxLabel for="my-group-reactive-checkbox-Enabled">Enabled</label>
          </uitk-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
