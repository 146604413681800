<div style="position:relative;top:-1.5px;left:365px;width:320px;z-index:1000;">
  <button [disabled]="!canDeleteJob" type="button" (click)="Delete()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
    <span class="uitk-icon uitk-icon-trash_delete sm-icon"></span> Delete
  </button>
  <button [disabled]="!canAddJob" type="button" (click)="NewJob()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
    <span class="uitk-icon uitk-icon-add sm-icon"></span> New Job
  </button>
  <button [disabled]="!canRunJob" type="button" (click)="RunNow()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
    <span class="uitk-icon uitk-icon-play_media sm-icon"></span> Run Now
  </button>
  <button [disabled]="!canEditJob" type="button" (click)="Save()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
    <span class="uitk-icon uitk-icon-save sm-icon"></span> Save
  </button>
</div>

<div style="position:relative;top:-30px;left:0px;min-width:600px;">
  <h5 style="margin:0px;padding:0px;">
    Config
  </h5>
  <hr class="line1" />
  <div class="uitk-l-grid">
    <form [formGroup]="jobForm">

      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label uitkLabel style="padding-right:4px;padding-top:3px;">System</label>
            <input id="System" uitkInput formControlName="System" />
            <uitk-form-field-error *ngIf="jobForm?.controls?.System?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="jobForm?.controls?.System?.invalid && jobForm?.controls?.System?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label uitkLabel style="padding-right: 5px;padding-top:3px;padding-left: 10px;">Title</label>
            <input id="titleField" uitkInput formControlName="Title" />
            <uitk-form-field-error *ngIf="jobForm?.controls?.Title?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="jobForm?.controls?.Title?.invalid && jobForm?.controls?.Title?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
      </div>

      <div class="uitk-l-grid__row">
      </div>

      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col">
          <label uitkLabel>Description</label>
          <uitk-form-field>
            <textarea #descriptionCtrl (window:resize)="onResize($event)" uitkTextarea formControlName="Description" uitkId="Descriptiontextarea"
                      [textLimit]="myTextLimit" aria-describedby="my-required-reactive-textarea-ht" style="height:20px;min-height:20px;position:relative;top:-10px;resize:vertical !important">
                  </textarea>
          </uitk-form-field>
        </div>
        <div style="width:5px;"></div>
        <div class="uitk-l-grid__col">
          <label uitkLabel>Compare</label>
          <uitk-form-field>
            <textarea #compareCtrl uitkTextarea formControlName="Compare" uitkId="Comparetextarea" [textLimit]="myTextLimit" aria-describedby="my-required-reactive-textarea-ht"
                      style="height:20px;min-height:20px;position:relative;top:-10px;resize:vertical !important">
                  </textarea>
          </uitk-form-field>
        </div>
      </div>

      <div class="uitk-l-grid__row">
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label uitkLabel style="padding-right: 5px;padding-top:3px;">Type </label>
            <uitk-dropdown uitkId="Typedropdown" [items]="types" (onChange)="changeEvent($event.value)" formControlName="Type" style="width:160px;"></uitk-dropdown>
            <uitk-form-field-error *ngIf="jobForm?.controls?.Type?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="jobForm?.controls?.Type?.invalid && jobForm?.controls?.Type?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col">
          <uitk-form-field>
            <label uitkLabel style="padding-right: 8px;padding-left:10px;padding-top:3px;">TimeOut(m) </label>
            <input id="TimeOutMin" uitkInput formControlName="TimeOut" style="width: 70px;" />
            <uitk-form-field-error *ngIf="jobForm?.controls?.TimeOut?.errors?.required == 'true'">Required</uitk-form-field-error>
            <uitk-form-field-error *ngIf="jobForm?.controls?.TimeOut?.invalid && jobForm?.controls?.TimeOut?.errors?.required != 'true'">Invalid</uitk-form-field-error>
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col" style="padding-left: 20px !important;position: relative; top: -2px;
            ">
          <uitk-form-field>
            <label uitkCheckboxLabel for="my-basic-reactive-checkbox">Allow Overlap</label>
            <input id="my-basic-reactive-checkbox" formControlName="checkbox" type="checkbox" uitkCheckbox style="padding-left: 10px;" />
          </uitk-form-field>
        </div>
        <div class="uitk-l-grid__col" *ngIf="hasOwners">
          <uitk-form-field>
            <label uitkLabel style="padding-top:3px;padding-right: 5px">Owner</label>
            <uitk-dropdown uitkId="singleSelect-template" [items]="owners" [enableSelectFlag]="true" formControlName="Owner" style="width:120px;"></uitk-dropdown>
            <uitk-form-field-error *ngIf="jobForm?.controls?.Owner?.errors?.required == 'true'">Required</uitk-form-field-error>
          </uitk-form-field>
        </div>
      </div>
      <!--WcfFields-->
      <div *ngIf="wcfFields">
        <uitk-form-field>
          <label uitkLabel style="padding-right: 5px;">URL </label>
          <input id="URL" uitkInput formControlName="URL" />
          <uitk-form-field-error *ngIf="jobForm?.controls?.URL?.errors?.required == 'true'">Required</uitk-form-field-error>
          <uitk-form-field-error *ngIf="jobForm?.controls?.URL?.invalid && jobForm?.controls?.URL?.errors?.required != 'true'">Invalid</uitk-form-field-error>
        </uitk-form-field>

        <div class="uitk-l-grid__row" style="padding: 5px;">
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 5px;">Contract </label>
              <input id="Contract" uitkInput formControlName="Contract" />
              <uitk-form-field-error *ngIf="jobForm?.controls?.Contract?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="jobForm?.controls?.Contract?.invalid && jobForm?.controls?.Contract?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 5px;padding-left: 10px;">Method </label>
              <input id="Method" uitkInput formControlName="Method" />
              <uitk-form-field-error *ngIf="jobForm?.controls?.Method?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="jobForm?.controls?.Method?.invalid && jobForm?.controls?.Method?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <!--RestFields-->
      <!--<div *ngIf="restFields">-->
      <div #restView style="display:'none'">

        <uitk-form-field>
          <label uitkLabel style="padding-right: 5px;padding-top:3px">URL </label>
          <input id="RestURL" uitkInput formControlName="RestURL" />
          <uitk-form-field-error *ngIf="jobForm?.controls?.RestURL?.errors?.required == 'true'">Required</uitk-form-field-error>
          <uitk-form-field-error *ngIf="jobForm?.controls?.RestURL?.invalid && jobForm?.controls?.RestURL?.errors?.required != 'true'">Invalid</uitk-form-field-error>
        </uitk-form-field>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 5px;padding-top:3px">Body </label>
              <textarea [textLimit]="2147483647" #restBodyCtrl uitkTextarea formControlName="Body" uitkId="Bodytextarea" aria-describedby="my-required-reactive-textarea-ht"
                        style="height:20px;min-height:20px;resize:vertical !important">
                    </textarea>
              <uitk-form-field-error *ngIf="jobForm?.controls?.Body?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="jobForm?.controls?.Body?.invalid && jobForm?.controls?.Body?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 5px;padding-top:3px;padding-left:20px">Verb </label>
              <uitk-dropdown uitkId="VerbTypedropdown" [items]="verbs" (onChange)="verbChangeEvent($event)" formControlName="Verb" style="width:160px;"></uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 5px;padding-top:3px">Headers</label>
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col uitk-u-flex__align--right" style="position:relative;top:24px;right:34px;z-index:100;">
            <div style="text-align:end;">
              <button type="button" (click)="clickHeaderAdd()" class="btn btn-hover">
                <span class="uitk-icon uitk-icon-add sm-icon" style="color: white"></span>
              </button>
            </div>
          </div>
        </div>
        <div style="position:relative;top:-8px;">
          <ag-grid-angular #agGrid style="width: 100%; height:100px;" id="jobListGrid" class="ag-theme-alpine"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="defaultColDef"
                           [rowSelection]="rowSelection"
                           (gridReady)="onGridReady($event)"
                           [frameworkComponents]="frameworkComponents"
                           [rowData]="rowData"
                           [gridOptions]="gridOptions"></ag-grid-angular>
        </div>
      </div>

      <!--SqlDatabase-->
      <!--<div *ngIf="sqlDatabaseFields">-->
      <div #sqlView style="display:'none'">

        <div class="uitk-l-grid__row">
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 5px; padding-top: 3px">Server </label>
              <input id="Server" uitkInput formControlName="Server" />
              <uitk-form-field-error *ngIf="jobForm?.controls?.Server?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="jobForm?.controls?.Server?.invalid && jobForm?.controls?.Server?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </div>
          <div class="uitk-l-grid__col">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 5px; padding-top: 3px; padding-left: 10px;">Database </label>
              <input id="Database" uitkInput formControlName="Database" />
              <uitk-form-field-error *ngIf="jobForm?.controls?.Database?.errors?.required == 'true'">Required</uitk-form-field-error>
              <uitk-form-field-error *ngIf="jobForm?.controls?.Database?.invalid && jobForm?.controls?.Database?.errors?.required != 'true'">Invalid</uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
        <uitk-form-field>
          <label uitkLabel style="padding-right: 5px; padding-top: 3px">Command </label>
          <textarea [textLimit]="2147483647" #sqlCommandCtrl uitkTextarea formControlName="Command" uitkId="Commandtextarea" aria-describedby="my-required-reactive-textarea-ht"
                    style="height:20px;min-height:20px;resize:vertical !important">
                </textarea>
          <uitk-form-field-error *ngIf="jobForm?.controls?.Command?.errors?.required == 'true'">Required</uitk-form-field-error>
          <uitk-form-field-error *ngIf="jobForm?.controls?.Command?.invalid && jobForm?.controls?.Command?.errors?.required != 'true'">Invalid</uitk-form-field-error>
        </uitk-form-field>
      </div>

      <!--PowerShell-->
      <!--<div *ngIf="powerShellFields">-->
      <div #psView style="display:'none'">

        <uitk-form-field>
          <label uitkLabel style="padding-right: 5px;padding-top:3px;">Command Type</label>
          <uitk-dropdown uitkId="singleSelect-template" [items]="cmdType" [enableSelectFlag]="true" formControlName="CmdType" style="width:120px;"></uitk-dropdown>
        </uitk-form-field>


        <uitk-form-field>
          <label uitkLabel style="padding-right: 5px; padding-top: 3px">Command </label>
          <textarea [textLimit]="2147483647" #psCommandCtrl uitkTextarea formControlName="PowerCommand" uitkId="PowerCommandtextarea"
                    aria-describedby="my-required-reactive-textarea-ht" style="height:20px;min-height:20px;resize:vertical !important"></textarea>
          <uitk-form-field-error *ngIf="jobForm?.controls?.PowerCommand?.errors?.required == 'true'">Required</uitk-form-field-error>
          <uitk-form-field-error *ngIf="jobForm?.controls?.PowerCommand?.invalid && jobForm?.controls?.PowerCommand?.errors?.required != 'true'">Invalid</uitk-form-field-error>
        </uitk-form-field>
      </div>
    </form>
  </div>
</div>
