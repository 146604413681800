import { Injectable } from '@angular/core';
import { Mode } from '../enums/mode.enum';

@Injectable({
  providedIn: 'root'
})
export class ModeService {
  mode: Mode = Mode.Initialize;

  constructor() { }
}
