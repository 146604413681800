import { Component, OnInit, ViewChild } from '@angular/core';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; 
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

interface environmentsList {
  label: string,
  value: string
}

@Component({
  selector: 'app-job-promotion',
  templateUrl: './job-promotion.component.html',
  styleUrls: ['./job-promotion.component.css']
})

export class JobPromotionComponent implements OnInit {

  promoteForm = new UntypedFormGroup({
    environment: new UntypedFormControl(''),
    responseData: new UntypedFormControl('')
  });

  myLabel: string = 'My Label';
  myHelperText: string = 'My Helper text';
  myCheckbox1: string = 'Checkbox 1';
  myCheckbox2: string = 'Checkbox 2';
  myCheckbox3: string = 'Checkbox 3';
  myValue1: boolean = false;
  myValue2: boolean = false;
  myValue3: boolean = false;
  destSelectedValue: any;
  selectedNodes: any;
  @ViewChild('mainView', { static: true }) mainView: any;
  rowData: any = [];
  systemNameList: any = [];
  environments: environmentsList[] = [];
  selectedJobs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public columnDefs: any;
  public defaultColDef: any;
  public rowSelection: any;
  selectedRows: any;
  apiUrl: any;
  responseData: any;
  lastHistorySelected: any;
  envList: any;
  resourceObj: Object = new Object;

  environmentList = [
    { name: 'Local', value: 'LOCAL' },
    { name: 'Development', value: 'DEVELOPMENT' },
    { name: 'Test', value: 'TEST' },
    { name: 'Staging', value: 'STAGING' },
    { name: 'Production', value: 'PRODUCTION' }
  ];

  constructor(
    private configService: ConfigService,
    protected httpClient: HttpClient,
    private jobSummaryService: JobSummaryService, ) {
    this.columnDefs = [
      { headerName: 'Select', field: 'select', maxWidth: 30, checkboxSelection: true },
      { headerName: 'System', field: 'system', maxWidth: 200},
      { headerName: 'Title', field: 'title' },
      { headerName: 'Status', field: 'status', maxWidth: 100,
      cellStyle: function (params: any) {
        if (params.value == 'Success') {
          return { backgroundColor: 'lightgreen' };
        }
        else if (params.value == 'Failure') {
          return { backgroundColor: 'red' };
        }
        else {
          return { backgroundColor: '' };
        }
      }   
    }
    ];
    this.defaultColDef = {
      flex: 1,
      resizable: true,
    };
     this.rowSelection = 'multiple';
  }

  onResize(event: any) {
    this.ngAfterViewInit();
  }

  @ViewChild('viewDiv', { static: true }) viewDiv: any;
  @ViewChild('viewResponse', { static: true }) viewResponse: any;

  ngAfterViewInit() { 
    
    var respHeightStr = this.viewResponse.nativeElement.style.height.replace('px', '');
    var respHeight: number = +respHeightStr;
 
    this.viewDiv.nativeElement.style.height = window.innerHeight - 187 + "px";
    this.viewResponse.nativeElement.style.width = window.innerWidth / 2 - 50 + "px";
    this.mainView.nativeElement.style.height = window.innerHeight - 220 + "px";
  }

  ngOnInit(): void {
    this.rowData = [];
    this.jobSummaryService.promoteSystemNameList$.subscribe((res: any) => { 
      res.forEach((x: any) => {
        x.status = '';
      });
      this.rowData = res;
       
    });
    this.environmentList.forEach(element => {
      if (element.name != this.configService.environment || (element.name == 'Local' && this.configService.environment == 'Local')) {
        this.environments.push({ label: element.name, value: element.value });
      }
    }); 

    this.jobSummaryService.deletePromoteList$.subscribe((res: any) => {  
      if(this.jobSummaryService.deletePromoteList == true){
      if (res != null) {   
        this.rowData.forEach((element:any,index:any)=>{
          if(element.jobId==res) {
            this.rowData.splice(index,1);            
            this.jobSummaryService.deletePromoteList = false;
          }
       });    
      } 
    }
  });
  }

  resizeEvent() {
    this.ngAfterViewInit();
  }

  onCellClicked(event: any) {

    if (event.column.colId == 'status') {

      if (this.lastHistorySelected == event.data.jobId) {
        if (this.viewResponse.nativeElement.style.height == '20px') {
          this.viewResponse.nativeElement.style.height = '270px';
        } else if (this.viewResponse.nativeElement.style.height == '270px') {
          this.viewResponse.nativeElement.style.height = '20px'
        }
      } else {
        this.lastHistorySelected = event.data.jobId;
        if (this.viewResponse.nativeElement.style.height == '20px') this.viewResponse.nativeElement.style.height = '270px';
      }
      if (event.data.status == 'Success') {
        this.promoteForm.controls["responseData"].setValue(event.data.status);
      } else {
        var response = '*** Error ***\n'
          + event.data.exceptionDetails
        this.promoteForm.controls["responseData"].setValue(response);
      }
      this.ngAfterViewInit();
    } else {

    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.forEachLeafNode((node: any) => {
      if (node.data.jobId == this.jobSummaryService.selectedRowId)
        node.setSelected(true);
    });
  }

  onSelectionChanged(params: any) {
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedNodes = this.gridApi.getSelectedNodes();
  }
  onClick() {

    this.promoteForm.controls["environment"].setErrors(null);

    this.envList = this.configService.configRef;
    switch (this.promoteForm.controls["environment"].value?.label) {
      case 'Local':
        this.apiUrl = this.envList.apiUrl_Local;
        break;
      case 'Development':
        this.apiUrl = this.envList.apiUrl_Development;
        break;
      case 'Test':
        this.apiUrl = this.envList.apiUrl_Test;
        break;
      case 'Staging':
        this.apiUrl = this.envList.apiUrl_Staging;
        break;
      case 'Production':
        this.apiUrl = this.envList.apiUrl_Production;
        break;
      default:
        this.apiUrl = "";
    }
    if (this.selectedRows != undefined && this.apiUrl != "")
      this.loop(this.selectedRows.shift());
    else { 
      this.promoteForm.controls["environment"].setErrors({ 'required': 'true' });
      alert("Please select atleast one record.");
    }
  }


  loop = (id: any) => {
    this.resourceObj = { envUrlToPromotTo: this.apiUrl, jobId: id.jobId };
    let itemsToUpdate: any = [];
    this.httpClient.post(this.configService.apiUrl + '/Scheduler/PromotJob', this.resourceObj, { responseType: 'text' })
      .toPromise().then((result) => {
        console.log(result);
        let rowNode = this.selectedNodes.find((x: any) => x.data.jobId == id.jobId);
        rowNode.data.status = "Success";
        itemsToUpdate.push(rowNode.data);
        this.gridApi.updateRowData({ update: itemsToUpdate });
        if (this.selectedRows.length) {
          this.loop(this.selectedRows.shift())
        }
      },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          let rowNode = this.selectedNodes.find((x: any) => x.data.jobId == id.jobId);
          rowNode.data.status = "Failure";
          rowNode.data.exceptionDetails = err.error;
          itemsToUpdate.push(rowNode.data);
          this.gridApi.updateRowData({ update: itemsToUpdate });
          if (this.selectedRows.length) {
            this.loop(this.selectedRows.shift())
          }
        });
  }

}
