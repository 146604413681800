<div class="modal-header">
        <h4 class="modal-title" style="font-family: Calibri; font-weight: bold; font-size: 20px;">{{ title }}</h4>     
        </div>
        <div class="modal-body"  style="font-family: Calibri; font-weight: bold; font-size: 15px;" >
          {{ message }}
        </div>
        <div class="modal-footer">
          <button type="button" style="font-family: Calibri; font-size: 15px;background:#C25608;border-color:#C25608;" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
          <button type="button" style="font-family: Calibri; font-size: 15px;background:#C25608;border-color:#C25608;" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
        
        </div>