// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service'


@Component({
  selector: 'app-button-renderer',
  template: `
    <button type="button" (click)="onClick($event)" class="btn btn-hover">
      <span class="uitk-icon uitk-icon-close sm-icon"></span>
    </button>
  `,
  styles: [
    `.btn {
      line-height: 18px;
      height:20px;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      border: 0px;
      background-color: transparent;
      position:relative;
      text-align:center;
      top:-2px;
    }
    .btn-hover:hover {
      border: 2px solid #C25608;
      border-radius: 4px;
      text-align: center;
    }`
  ]
})

export class ButtonRendererComponent implements ICellRendererAngularComp {
 
  constructor(
    private jobSummaryService: JobSummaryService,
  ) {
  }
  params: any;
  agInit(params: any): void {
    this.params = params;
  }

  //ngOnInit() {    
  //}


  refresh(params?: any): boolean {
    return true;
  }

  onClick($event: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
}
