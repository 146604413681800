import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/shared/services/config.service';
import * as moment from 'moment';
import { ModalDialogService } from '../modaldialogbox/modal-dialog.service';

import { Observable } from 'rxjs';

var OwnerArray: any = [];
var OwnerSelect: any = [];

@Component({
  selector: 'app-job-alerts',
  templateUrl: './job-alerts.component.html',
  styleUrls: ['./job-alerts.component.css']
})
export class JobAlertsComponent implements OnInit {

  ngOnInit(): void {
    this.jobSummaryService.alertClearData$.subscribe((x: any) => {
      if (this.alertClearDataFirstTime == true)
        this.alertClearDataFirstTime = false;

      else {
        this.rowData = [];
        this.getRecords();
      }

    });
    this.owners = [];
    for (let i = 0; i < this.authorizationService.owners.length; i++) {
      var item = { label: this.authorizationService.owners[i], value: this.authorizationService.owners[i] };
      this.owners.push(item);
    }
    console.log(this.authorizationService.owners);
    if (this.authorizationService.owners.length == 1) this.hasOwners = false;
  }

  onResize(event: any) {
    this.ngAfterViewInit();
  }
  @ViewChild('viewDiv', { static: true }) viewDiv: any;
  @ViewChild('mainView', { static: true }) main: any;
  @ViewChild('formControl', { static: true }) formControl: any;
  ngAfterViewInit() {

    this.viewDiv.nativeElement.style.width = window.innerWidth / 2 - 50 + "px";
    this.formControl.nativeElement.style.height = window.innerHeight - 125 + "px";
    this.viewDiv.nativeElement.style.height = window.innerHeight - 125 + "px";
  }

  public alertClearDataFirstTime = true;
  public ID: any;
  public owners: any = [];
  public hasOwners = true;
  public saveobj: any = [];
  public user: string = "";
  public OwnerArray: any = [];
  public OwnerSelect: any = [];
  public intervel: any;
  public gridApi: any;
  public gridColumnApi: any;
  public jobId: any;
  public columnDefs: any;
  public defaultColDef: any;
  public rowSelection: any = 'single';
  public rowData: any = [];
  public initialData: any;
  public getRowNodeId: any;
  public CreatedBy: string = "";
  public CreatedDate: any;
  public UpdatedBy: string = "";
  public UpdatedDate: any;
  public EnteredData: any = [];
  public id: any = 0;
  public system: any;
  public title: any;
  public gridData: any;
  public owner: any;
  public value: any = 1;
  public gridOptions: any;
  public disableDelete: boolean = true;


  constructor(private authorizationService: AuthorizationService,
    protected httpClient: HttpClient,
    private jobSummaryService: JobSummaryService,
    public modalDialogService: ModalDialogService,
    private configService: ConfigService) {
    this.user = this.authorizationService.msId;
    this.jobId = this.jobSummaryService.selectedRowId;
    console.log(this.authorizationService.owners.length);
    this.columnDefs = [
      {
        headerName: 'Owner',
        field: 'owner',
        maxWidth: 100,
        editable: false,
        filter: true,
        sortable: true,
        hide: (this.authorizationService.owners.length == 1 ? true : false),
      },
      {
        headerName: 'System', field: 'system', minWidth: 0, sortable: true, filter: true, editable: false,
      },
      {
        headerName: 'Title', field: 'title', minWidth: 0, sortable: true, filter: true, editable: false,
      },
      {
        headerName: 'Email', field: 'email', minWidth: 0, sortable: true, filter: true, editable: true,
      },
      {
        headerName: 'Success',
        field: 'success',
        maxWidth: 100,
        cellRenderer: function (params: any) {
          var input = document.createElement('input');
          input.style.width = "17px";
          input.style.height = "17px";
          input.type = "checkbox";
          input.checked = params.value;
          input.addEventListener('click', function (event) {
            params.value = !params.value;
            params.data.success = params.value;

          });
          return input;
        }
      },
      {
        headerName: 'Failure',
        field: 'failure',
        maxWidth: 100,
        cellRenderer: function (params: any) {
          var input = document.createElement('input');
          input.style.width = "17px";
          input.style.height = "17px";
          input.type = "checkbox";
          input.checked = params.value;
          input.addEventListener('click', function (event) {
            params.value = !params.value;
            params.data.failure = params.value;
          });
          return input;
        }

      },
    ];

    this.getRowNodeId = function (gridRowdata: any) {
      return gridRowdata.id;
    };

    this.defaultColDef = {
      flex: 1,
      resizable: true,
    };
    //this.rowSelection = 'single';
    this.gridOptions = {
      singleClickEdit: true,
      stopEditingWhenGridLosesFocus: true,
      suppressMultiRangeSelection: true,
    };
  }
  public disableButtons$: Observable<boolean> = this.jobSummaryService.isEnableDeleteButton$;

  selectedRecord: any;
  onRowClicked(data: any) {

    this.gridApi.forEachNode((node: any) => {
      if (node.data != data.data) {
        node.setSelected(false);
      }
      else {
        node.setSelected(true);
      }
    });

    this.ID = data.data.id;
    this.selectedRecord = data.rowIndex;
    this.jobSummaryService.isEnableDeleteButton$.next(false);
    this.disableDelete = false;
  }

  addRow() {

    this.disableDelete = true;
    //for adding new row in grid
    this.system = this.jobSummaryService.selectedRowData.systemName;
    this.title = this.jobSummaryService.selectedRowData.title;
    this.owner = this.jobSummaryService.selectedOwnerID;
    var newData = { id: this.id, owner: this.owner, system: this.system, title: this.title, email: '', success: false, failure: true };
    this.rowData.push(newData);
    this.gridApi.setRowData(this.rowData);
    this.gridApi.setFocusedCell(this.rowData.length - 1, 'Email');

  }

  delete() {
    //for deleting the row from grid
    this.modalDialogService.confirm('Warning', 'Are you sure, you want to delete this record?').then((state: any) => {
      if (state) {
        if (this.ID == 0) {
          this.rowData.splice(this.selectedRecord, 1)

          this.gridApi.setRowData(this.rowData);
          this.gridApi.redrawRows();
          this.disableDelete = true;
        }
        else {
          this.httpClient.delete(this.configService.apiUrl + '/Alerts/DeleteUser/delete?id=' + this.ID).subscribe((data: any) => {
            this.getRecords();
            alert('Record deleted');

            this.rowData.splice(this.selectedRecord, 1)

            this.gridApi.setRowData(this.rowData);
            this.gridApi.redrawRows();
            this.disableDelete = true;

          }, (error: any) => {
            //404 is sent when there is no data
            if (error.status != 404) {
              console.log(error);
              alert('An error occurred while attempting to delete job.  Please try again.  Error: ' + error.message);
            }
          });
        }
      }
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let Owner = this.jobSummaryService.selectedOwnerID;
    let jobId = this.jobSummaryService.selectedRowData == undefined ? "" : this.jobSummaryService.selectedRowData.jobId;
    if (jobId == "") {
      this.httpClient.get(this.configService.apiUrl + '/Alerts/GetAlerts').subscribe((data: any) => {
        var myData: any = [];
        for (var i = 0; i < data.length; i++) {
          if (this.authorizationService.owners.indexOf(data[i]["owner"]) > -1)
            myData.push(data[i]);
        }
        this.rowData = myData;
        this.gridData = this.rowData.map((x: any) => Object.assign({}, x));;
      });
    } else {

      this.httpClient.get(this.configService.apiUrl + '/Alerts/GetAlerts?jobId=' + jobId + '&owner=' + Owner).subscribe((data: any) => {
        this.rowData = data;
        this.gridData = this.rowData.map((x: any) => Object.assign({}, x));;
      });
    }

  }
  getRecords() {
    let jobId = this.jobSummaryService.selectedRowData == undefined ? "" : this.jobSummaryService.selectedRowData.jobId;
    if (jobId == "") {
      this.httpClient.get(this.configService.apiUrl + '/Alerts/GetAlerts').subscribe((data: any) => {
        this.rowData = data;
        this.gridData = this.rowData.map((x: any) => Object.assign({}, x));;
      });
    } else {
      setTimeout(() => {
        let Owner = this.jobSummaryService.selectedOwnerID;
        this.httpClient.get(this.configService.apiUrl + '/Alerts/GetAlerts?jobId=' + jobId + '&owner=' + Owner).subscribe((data: any) => {
          this.rowData = data;
          this.gridData = this.rowData.map((x: any) => Object.assign({}, x));;
        });
      }, 200);
    }
    this.jobSummaryService.isEnableDeleteButton$.next(true);
  }
  checkButton() {
    this.jobId = this.jobSummaryService.selectedRowData == undefined ? "" : this.jobSummaryService.selectedRowData.jobId;
    if (this.jobId == "") {
      return true;
    }
    else {
      return false;
    }
  }

  dataobj: any;
  save() {
    //for saving and updating records
    const dataSource = this.rowData;
    const initial = this.gridData;
    var keepGoing = true;

    dataSource.forEach((dsElement: any) => {
      var item: any;
      if (keepGoing == true) {
        this.dataobj = {
          email: dsElement.email,
          title: dsElement.title,
          system: dsElement.system,
        };

        if (this.dataobj.email == null || this.dataobj.email == '' || this.dataobj.email.includes('@') != true || this.dataobj.system == null || this.dataobj.system == '' || this.dataobj.title == null || this.dataobj.title == '') {
          alert('please enter valid data');
          keepGoing = false;
        }
      }
    });

    if (keepGoing == true) {
      dataSource.forEach((dsElement: any) => {
        var item: any;
        if (keepGoing == true) {
          if (dsElement.id != 0 && initial != undefined) {
            initial.forEach((inElement: any) => {
              item = "";
              if (dsElement.id == inElement.id) {
                item = inElement;
              }
              if (item != undefined) {
                //Compare
                if (JSON.stringify(dsElement) != JSON.stringify(item) && item != "") {
                  //Data has changed 
                  this.dataobj = {
                    id: dsElement.id,
                    jobId: dsElement.jobId,
                    owner: dsElement.owner,
                    title: dsElement.title,
                    system: dsElement.system,
                    email: dsElement.email,
                    success: dsElement.success,
                    failure: dsElement.failure,
                    createdBy: this.authorizationService.msId,
                    createdDate: dsElement.createdDate,
                    updatedBy: this.authorizationService.msId,
                    updatedDate: dsElement.updatedDate,
                  };
                  const promise = this.httpClient.put(this.configService.apiUrl + '/Alerts/UpdateAlerts', this.dataobj).toPromise();
                  promise.then(x => {
                  }, (error: any) => {
                    console.log(error);
                    let er = typeof error.error == "string" ? error.error : (error.error?.title == null ? "Not available" : error.error?.title);
                    let msg = 'An error occurred while attempting to save the data.  \n\nSave Error:\n' + error.message + "\n\n" + "Error Details:\n" + er;
                    alert(msg);
                  });
                }
              }
            });
          }
          else {
            this.dataobj = {
              id: 0,
              jobId: this.jobSummaryService.selectedRowId,
              owner: dsElement.owner,
              title: dsElement.title,
              system: dsElement.system,
              email: dsElement.email,
              success: dsElement.success,
              failure: dsElement.failure,
              createdBy: this.authorizationService.msId,
              createdDate: dsElement.createdDate,
              updatedBy: this.authorizationService.msId,
              updatedDate: dsElement.updatedDate,
            };
            this.httpClient.post(this.configService.apiUrl + '/Alerts/SaveAlerts', this.dataobj).subscribe((data: any) => {
            }, (error: any) => {
              console.log(error);
              let er = typeof error.error == "string" ? error.error : (error.error?.title == null ? "Not available" : error.error?.title);
              let msg = 'An error occurred while attempting to save the data.  \n\nSave Error:\n' + error.message + "\n\n" + "Error Details:\n" + er;
              alert(msg);
            });
          }
        }
      });
    }
    if (keepGoing == true) {
      this.getRecords();
      alert("Record Saved");
    }
  }

}
