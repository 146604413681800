<div style="position:relative;top:-1.5px;left:365px;width:320px;z-index:1000;">
  <button type="button" (click)="onClick()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
    <span class="uitk-icon uitk-icon-refresh sm-icon"></span> Refresh
  </button>
</div> 
<div #mainView style="position:relative;top:-30px;">
  <h5 style="margin:0px;padding:0px;">
    History
  </h5>
  <hr class="line1" />
  <Form [formGroup]="historyForm">
    <div class="uitk-l-grid" style="margin:0px;margin-top:2px;width:100%;min-width:650px;">
      <div class="uitk-l-grid__row" style="margin:0px;padding:0px;">

        <div class="uitk-l-grid__col uitk-u-flex__align--right" style="margin:0px;padding:0px;">
          <div style="position: relative; top: -1.5px;">
            <uitk-form-field>
              <label uitkLabel style="padding-right: 4px;padding-top:5px;">Refresh(m)</label>
              <uitk-dropdown uitkId="singleSelect-template" [items]="intervel" [enableSelectFlag]="true" formControlName="intervel" style="width:100px;"
                             (ngModelChange)="onIntervelChange()"></uitk-dropdown>
            </uitk-form-field>
          </div>
        </div>

        <div class="uitk-l-grid__col uitk-u-flex__align--right" style="margin:0px;padding:0px;position:relative;top:2px;">
          <div style="position: relative; top: -4px; float: right;">
            <uitk-form-field>
              <label uitkLabel style="padding-right:4px;padding-top:5px;padding-left:10px;">
                Back(h)
              </label>
              <input #hoursBack id="hoursBack" uitkInput formControlName="hoursBack"
                     style="width: 70px;padding:0px 10px 4px 0px !important;text-align:center"
                     (ngModelChange)="onHoursBackChange()" />
              <uitk-form-field-error *ngIf="
                                     historyForm?.controls?.hoursBack?.invalid &&
                                     (historyForm?.controls?.hoursBack?.touched  || historyForm?.controls?.hoursBack?.dirty)">
                Invalid
              </uitk-form-field-error>
            </uitk-form-field>
          </div>
        </div>
        <div class="uitk-l-grid__col uitk-u-flex__align--left" style="margin:0px;padding:0px;position:relative;top:0px;left:-18px;">
          <button type="button" class="btn btn-hover" (click)="clearHoursBack()" style="position:relative;">x</button>
        </div>

      </div>

      <div class="uitk-l-grid__row" style="margin:0px;padding:0px;position:relative;top:0px;">
        <div class="uitk-l-grid__col" style="margin:0px;padding:0px;">

          <div *ngIf="historyForm.controls['hoursBack'].value==''" style="position: relative; top: 4px;">
            <!--<uitk-form-field>-->
            <label>
              From
              <div style="width:2px;"></div>
              <div *ngIf="(historyForm?.controls?.fromDatePicker?.errors?.required && historyForm?.controls?.fromDatePicker?.touched) || historyForm?.controls?.fromDatePicker?.valid==false"
                   style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
                <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;"></span>
                <span style="position:relative;top:2px;left:5px;">
                  Invalid
                </span>
              </div>
              <input #fromDateCtrl class="datetime datetime-hover datetime-select" [owlDateTime]="dt2"
                     placeholder="Date Time" style="width:130px;height:24px;position:relative;top:-2px;"
                     formControlName="fromDatePicker"
                     hour12Timer="true"
                     (ngModelChange)="onFromDateChange()">
              <div style="width:1px;"></div>
              <span [owlDateTimeTrigger]="dt2" class="uitk-icon uitk-icon-calendar md-icon" style="position:relative;top:-2px;"></span>
              <owl-date-time #dt2 [hour12Timer]="true"></owl-date-time>
            </label>

            <!--</uitk-form-field>-->
          </div>
        </div>

        <div class="uitk-l-grid__col" style="margin:0px;padding:0px;">
          <div *ngIf="(historyForm.controls['checkboxNow'].value == false && historyForm.controls['hoursBack'].value=='')" style="position: relative; top: 4px;padding-left:5px;">
            <!--<uitk-form-field>-->
            <label>
              To
              <div style="width:2px;"></div>
              <div *ngIf="(historyForm?.controls?.toDatePicker?.errors?.required && historyForm?.controls?.toDatePicker?.touched) || historyForm?.controls?.toDatePicker?.valid==false"
                   style="background-color:red;color:white;height:24px;width:80px;position:relative;top:-2px;">
                <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;background-color:red;position:relative;top:4px;left:2px"></span>
                <span style="position:relative;top:2px;left:5px;">
                  Invalid
                </span>
              </div>
              <input class="datetime datetime-hover datetime-select" [owlDateTime]="dt1"
                     placeholder="Date Time" style="width:130px;height:24px;position:relative;top:-2px;"
                     formControlName="toDatePicker"
                     hour12Timer="true"
                     (ngModelChange)="onToDateChange()">
              <div style="width:1px;"></div>
              <span [owlDateTimeTrigger]="dt1" class="uitk-icon uitk-icon-calendar md-icon" style="position:relative;top:-2px;"></span>
              <owl-date-time #dt1 [hour12Timer]="true"></owl-date-time>
            </label>

            <!--</uitk-form-field>-->
          </div>
        </div>

        <div class="uitk-l-grid__col" style="margin:0px;padding:0px;">
          <div style="position: relative; top: -4px;left:0px;" *ngIf="historyForm.controls['hoursBack'].value==''">
            <label>
              <div *ngIf="historyForm.controls['checkboxNow'].value==true" style="position:relative;top:8px;">To</div>
              <uitk-form-field>
                <label uitkCheckboxLabel for="my-basic-reactive-checkbox" style="padding-right: 4px;padding-top:2px;padding-left:2px;">Now</label>
                <input id="my-basic-reactive-checkbox" formControlName="checkboxNow" type="checkbox" uitkCheckbox
                       (change)="checkBoxNowEvent()"
                       style="padding-left: 10px;" />
              </uitk-form-field>
            </label>
          </div>

        </div>

      </div>

      <div class="uitk-l-grid__row" style="margin:0px;padding:0px;position:relative;top:-10px;">
        <div class="uitk-l-grid__col" style="margin:0px;padding:0px;width:100%;">
          <div style="position: relative; top: -1.5px; right:-1px; width: 100px;;">
            <label uitkLabel style="padding-right: 3px;">Response</label>
            <uitk-form-field>
              <textarea [textLimit]="2147483647" 
                        #viewResponse uitkTextarea formControlName="response"
                        uitkId="Responsetextarea"
                        aria-describedby="my-required-reactive-textarea-ht"
                        style="padding: 0px;height:20px;min-height:20px;width:100%;position:relative;top:-10px;resize:vertical !important"
                        (resize)="resizeEvent()"></textarea>
            </uitk-form-field>
          </div>
        </div>
      </div>
      <div class="uitk-l-grid__row" style="margin:0px;padding:0px;position:relative;top:-10px;">
        <div class="uitk-l-grid__col" style="margin:0px;padding:0px;width:100%;">
          <div #viewDiv (window:resize)="onResize($event)">
            <ag-grid-angular #agGrid
                             style="width: 100%;height:100%;"
                             id="jobListGrid"
                             class="ag-theme-alpine"
                             [columnDefs]="columnDefs"
                             [defaultColDef]="defaultColDef"
                             [rowSelection]="rowSelection"
                             [rowData]="rowData"
                             (rowClicked)="onRowClicked($event)"
                             (gridReady)="onGridReady($event)"></ag-grid-angular>

          </div>
        </div>
      </div>
    </div>
  </Form>
</div>
