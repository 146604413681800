<!--App Page-->
<router-outlet>
</router-outlet>
<!-- <div class="loader"></div> -->
<!-- <ng-http-loader 
    [backdrop]="false"
    [backgroundColor]="'#C25608'"
    [debounceDelay]="100"
    [extraDuration]="300"
    [minDuration]="300"
    [spinner]="spinnerStyle.skWave"
    >
</ng-http-loader>
<ng-http-loader></ng-http-loader> -->