<div *ngIf="isLoaded">
  <div *ngIf="canViewJobListing==false">
    You are not authorized to use this application...
  </div>
  <div *ngIf="canViewJobListing" style="height:100%;padding:0px;margin:0px;">
    <!--Header-->
    <uitk-header style="padding:0px;margin:0px;">
      <uitk-header-logo style="height:40px !important;">
        <img src="assets/Images/Optum.png" style="width:110px;height:40px;position:absolute;top:4px;" />
        <!--
            <img class="uitk-c-header__logo uitk-c-header__logo--small uitk-c-header__product-name" src="assets/Images/Optum.png" />
            <img class="uitk-c-header__logo uitk-c-header__logo--medium uitk-c-header__logo--large" src="assets/Images/Optum.png" />
        -->
        <div style="position:absolute;top:20px;left:150px;">
          <h6 style="color:#FFFFFF;">|</h6>
        </div>
        <div style="position:absolute;top:20px;left:160px;">
          <h6 style="color:#FCAE00;">Service Scheduler (<span style="color:white;">{{environment}}</span>)</h6>
        </div>
        <div style="position:absolute;top:10px;right:20px;">
          <button class="uitk-c-tooltip" aria-describedby="my-basic-input4-tooltip" (click)="Help()" style="background-color:transparent;">
            <span class="uitk-icon uitk-icon-help_filled md-icon"></span>
            <span role="tooltip" class="uitk-c-tooltip__text uitk-c-tooltip__bottom-left" id="my-basic-input5-tooltip"
                  style="background-color:#cc7109;padding:2px;margin:0px;">Need Help?{{jobId}}</span>
          </button>
        </div>
      </uitk-header-logo>
    </uitk-header>

    <!--Body-->
    <main id="main-content" class="wrapper" tabindex="-1" style="outline:none;position:absolute; top: 38px;height:80%;width:100%;min-width:1160px;padding:0px;margin:0px;">
      <div class="uitk-l-grid">
        <div class="uitk-l-grid__row">
          <!--The JobListing-->
          <div class="uitk-l-grid__col" style="float:left;display:inline-block;width:50%;min-width:1px;">
            <app-job-list></app-job-list>
          </div>
          <!--The Job -->
          <div class="uitk-l-grid__col" style="float:right;display:inline-block;width:50%;min-width:1px;">
            <div style="position:relative;top:0px;left:90px;width:300px;z-index:1000;">
              <button [disabled]="!canViewJob" type="button" (click)="ShowConfig()" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs">
                <span class="uitk-icon uitk-icon-edit sm-icon" style="color:white;"></span> Config</button>
                <button  type="button" (click)="ShowAlerts()" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs">
                  <span class="uitk-icon uitk-icon-alert_hollow sm-icon" style="color:white;"></span> Alerts</button>
              <button [disabled]="!canViewHistory" type="button" (click)="ShowHistory()" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs">
                <span class="uitk-icon uitk-icon-history sm-icon" style="color:white;"></span> History</button>
              <button [disabled]="!canPromote" type="button" (click)="ShowPromotion()" class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs">
                <span class="uitk-icon uitk-icon-process sm-icon" style="color:white;"></span> Promote</button>

            </div>
            <!--Job Configuration-->
            <div *ngIf="viewJobConfig && canViewJob" style="position:relative;top:-40px;">
              <!--Job Config-->
              <div class="uitk-l-grid__col" *ngIf="canViewJobListing">
                <app-job-configure></app-job-configure>
              </div>
              <!--Job Schedule-->
              <div class="uitk-l-grid__col">
                <app-schedule></app-schedule>
              </div>
            </div>
            <!--Job History-->
            <div *ngIf="viewJobHistory && canViewHistory" class="uitk-l-grid__col" style="position:relative;top:-40px;">
              <app-history-list></app-history-list>
            </div>
            <!--The Promotion-->
            <div *ngIf="viewJobPromotion && canPromote" class="uitk-l-grid__col" style="position:relative;top:-40px;">
              <app-job-promotion></app-job-promotion>
            </div>
            <!--The Alerts-->
            <div *ngIf="viewJobAlerts" class="uitk-l-grid__col" style="position:relative;top:-40px;">
              <app-job-alerts></app-job-alerts>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!--Footer-->
    <uitk-footer class="footer">
    </uitk-footer>

  </div>
</div>

