import { Component, HostBinding, Renderer2, HostListener, Output, ElementRef, EventEmitter, Directive, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { ModeService } from 'src/app/shared/services/mode.service';
import * as moment from 'moment';
import { ConfigService } from 'src/app/shared/services/config.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, interval, Subscription } from 'rxjs';

var fromDateJs: any;
var toDateJs: any;
var responseLimit: number = 2147483647;

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.css']
})

export class HistoryListComponent implements OnInit {

  private updateSubscription: Subscription = new Subscription;

  //my code
  public intervel: any;
  public gridApi: any;
  public gridColumnApi: any;
  public jobId: any;
  public columnDefs: any;
  public defaultColDef: any;
  public rowSelection: any;
  public rowData: any = [];

  historyForm = new UntypedFormGroup({
    fromDatePicker: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]),
    toDatePicker: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')]),
    intervel: new UntypedFormControl(''),
    response: new UntypedFormControl(''),
    checkboxNow: new UntypedFormControl(''),
    hoursBack: new UntypedFormControl('', [Validators.pattern('^\\d*$')]),
  });

  constructor(
    public modeService: ModeService,
    protected httpClient: HttpClient,
    private configService: ConfigService,
    private jobSummaryService: JobSummaryService,
  ) {

    this.intervel = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
    ];

    this.columnDefs = [
      {
        headerName: 'StartDate', field: 'startDate', width: 200, sortable: true, filter: true,
        valueFormatter: function (rowData: any) {
          if (rowData.value != null) {
            return moment(rowData.value).format('MM/DD/YYYY hh:mm A');
          }
          else
            return null;
        }
      },
      {
        headerName: 'EndDate', field: 'completeDate', width: 200, sortable: true, filter: true,
        valueFormatter: function (rowData: any) {
          if (rowData.value != null) {
            return moment(rowData.value).format('MM/DD/YYYY hh:mm A');
          }
          else
            return null;
        }
      },
      { headerName: 'Duration(ms)', field: 'jobDuration', width: 100, sortable: true, filter: true, },
      {
        headerName: 'Status', field: 'status', width: 100, sortable: true, filter: true,
        cellStyle: function (params: any) {
          if (params.value == 'Success') {
            return { backgroundColor: 'lightgreen' };
          } else if (params.value == 'InProcess') {
            return { backgroundColor: 'lightyellow' };
          }
          else if (params.value == 'SystemFailure') {
            return { backgroundColor: 'red' };
          }
          else {
            return { backgroundColor: 'salmon' };
          }
        }
      }
    ];

    this.defaultColDef = {
      flex: 1,
      resizable: true
    };
    this.rowSelection = 'single';
  }

  formIsActive: boolean = false;
  jobListSelectedFirstTime: boolean = true;
  configClearDataFirstTime: boolean = true;
  ngOnInit(): void {
    this.formIsActive = true;

    this.jobSummaryService.jobListSelected$.subscribe((x: any) => {
      if (this.jobListSelectedFirstTime == true)
        this.jobListSelectedFirstTime = false;
      else
        this.getRecords();
    });

    this.jobSummaryService.configClearData$.subscribe((x: any) => {
      if (this.configClearDataFirstTime == true)
        this.configClearDataFirstTime = false;
      else {
        this.rowData = [];
        this.historyForm.controls['response'].setValue('');
        this.getRecords();
      }

    });



    //Set Defaults
    //Set hours back to default to 2
    if (localStorage.getItem("hoursBack") != 'null' && localStorage.getItem("hoursBack") != 'NaN') {
      this.historyForm.controls['hoursBack'].setValue(localStorage.getItem("hoursBack"));
    } else {
      this.historyForm.controls['hoursBack'].setValue(2);
      localStorage.setItem("hoursBack", this.historyForm.controls['hoursBack'].value);
    }

    //StartDate 
    if (localStorage.getItem("fromDate") != 'null' && localStorage.getItem("fromDate") != 'NaN') {
      fromDateJs = new Date(localStorage.getItem("fromDate") as string);
      this.historyForm.controls['fromDatePicker'].setValue(fromDateJs);
    } else {
      //Set from to 5 hours back from now
      let initialFromDate = new Date();
      initialFromDate.setHours(initialFromDate.getHours() - 5);
      fromDateJs = initialFromDate;
      this.historyForm.controls['fromDatePicker'].setValue(fromDateJs);
      localStorage.setItem("fromDate", this.historyForm.controls['fromDatePicker'].value);
    }

    //EndDate 
    if (localStorage.getItem("toDate") != 'null' && localStorage.getItem("toDate") != 'NaN') {
      toDateJs = new Date(localStorage.getItem("toDate") as string);
      this.historyForm.controls['toDatePicker'].setValue(toDateJs);
    } else {
      //Set to now
      let initialToDate = new Date();
      initialToDate.setHours(initialToDate.getHours());
      toDateJs = initialToDate;
      this.historyForm.controls['toDatePicker'].setValue(toDateJs);
      localStorage.setItem("toDate", this.historyForm.controls['toDatePicker'].value);
    }

    //Check now
    if (localStorage.getItem("checkboxNow") != 'null' && localStorage.getItem("checkboxNow") != 'NaN') {
      this.historyForm.controls['checkboxNow'].setValue(localStorage.getItem("checkboxNow") == 'false' ? false : true);
    } else {
      this.historyForm.controls['checkboxNow'].setValue(true);
      localStorage.setItem("checkboxNow", this.historyForm.controls['checkboxNow'].value);
    }

    //Interval
    if (localStorage.getItem("intervel") != 'null' && localStorage.getItem("intervel") != 'NaN' && localStorage.getItem("intervel") != null) {
      let selValueS = localStorage.getItem('intervel') as unknown;
      this.historyForm.controls['intervel'].setValue(this.intervel[Number(selValueS) - 1]);
      this.setRefreshInterval(Number(localStorage.getItem("intervel")) * 60 * 1000);
    }

    this.getRecords();
  }

  onResize(event: any) {
    this.ngAfterViewInit();
  }

  @ViewChild('viewDiv', { static: true }) viewDiv: any;
  @ViewChild('viewResponse', { static: true }) viewResponse: any;
  @ViewChild('mainView', { static: true }) mainView: any;
  @ViewChild('hoursBack', { static: true }) hoursBack: any;
  @ViewChild('fromDateCtrl', { static: true }) fromDateCtrl: any;

  ngAfterViewInit() {
    let offset = 43;
    if (this.historyForm.controls['hoursBack'].value != '') offset = 0;

    var respHeightStr = this.viewResponse.nativeElement.style.height.replace('px', '');
    var respHeight: number = +respHeightStr;

    this.viewDiv.nativeElement.style.height = window.innerHeight - (172 + offset + respHeight) + "px";
    this.viewResponse.nativeElement.style.width = window.innerWidth / 2 - 50 + "px";
    this.mainView.nativeElement.style.height = window.innerHeight - 215 + "px";

  }

  ngAfterContentChecked() {
    this.validateDateSettings();
  }

  //on grid
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  //Get Histories
  public getRecords(): void {

    if (this.formIsActive == false) return;

    if (this.jobId != undefined && this.jobSummaryService.selectedRowId == undefined) this.rowData = [];

    this.jobId = this.jobSummaryService.selectedRowId;

    if (this.jobId == undefined) return;

    console.log("Getting History " + this.jobId);

    var fromDateTime: any;
    var toDateTime: any;

    if (this.historyForm.controls['hoursBack'].value != "") {
      toDateTime = null;
      fromDateTime = new Date();
      fromDateTime.setHours(fromDateTime.getHours() - this.historyForm.controls['hoursBack'].value);
    } else {
      if (this.historyForm.controls['checkboxNow'].value == true) {
        toDateTime = null;
        fromDateTime = this.historyForm.controls['fromDatePicker'].value;
      } else {
        toDateTime = this.historyForm.controls['toDatePicker'].value;
        fromDateTime = this.historyForm.controls['fromDatePicker'].value;
      }
    }

    if (fromDateTime != undefined && fromDateTime != '') {
      let request = {
        'jobId': this.jobId,
        'startDate':fromDateTime,
        'endDate': toDateTime,
        'offset': new Date().getTimezoneOffset()
      };

      //Clear current data
      this.rowData = [];
      this.historyForm.controls['response'].setValue('');
      //Get the history
      this.httpClient.post(this.configService.apiUrl + '/Scheduler/GetHistories', request).subscribe((results: any) => {
        this.rowData = results;
      }, (error: any) => {
        //404 is sent when there is no data
        if (error.status != 404) {
          alert('An error occurred while getting job history.  Please try again.  Error: ' + error.message);
        }
      });

      this.ngAfterViewInit();
    }
  }

  onClick() {
    this.getRecords();
  }

  clearHoursBack() {
    this.historyForm.controls['hoursBack'].setValue('');
    this.hoursBack.nativeElement.focus();
  }

  onHoursBackChange() {
    this.ngAfterViewInit();

    localStorage.setItem("hoursBack", this.historyForm.controls['hoursBack'].value);
    if (this.historyForm.controls['hoursBack'].value == "") {
      setTimeout(() => {
        this.fromDateCtrl.nativeElement.focus();
      }, 0);
    }
    this.getRecords();
  }

  checkBoxNowEvent() {
    if (this.historyForm.controls['checkboxNow'].value == false) {
      let currentDate = new Date();
      this.historyForm.controls['toDatePicker'].setValue(currentDate);
      localStorage.setItem("toDate", this.historyForm.controls['toDatePicker'].value);
    }
    localStorage.setItem("checkboxNow", this.historyForm.controls['checkboxNow'].value);
    this.validateDateSettings();
    this.getRecords();
  }

  onIntervelChange() {
    localStorage.setItem("intervel", this.historyForm.controls['intervel'].value.value);
    if (localStorage.getItem("intervel") != 'null' && localStorage.getItem("intervel") != 'NaN') {
      this.setRefreshInterval(Number(localStorage.getItem("intervel")) * 60 * 1000);
    } else {
      this.updateSubscription.unsubscribe();
    }
    this.getRecords();
  }

  setRefreshInterval(intervalms: any) {
    this.updateSubscription = interval(intervalms).subscribe(
      (val: any) => {
        this.getRecords()
      }
    );
  }

  onFromDateChange() {
    localStorage.setItem("fromDate", this.historyForm.controls['fromDatePicker'].value);
    fromDateJs = this.historyForm.controls['fromDatePicker'].value;
    this.validateDateSettings();
    this.getRecords();

  }

  onToDateChange() {
    localStorage.setItem("toDate", this.historyForm.controls['toDatePicker'].value);
    toDateJs = this.historyForm.controls['toDatePicker'].value;
    this.validateDateSettings();
    this.getRecords();
  }

  validateDateSettings() {
    //Validate dates
    if (this.historyForm.controls['checkboxNow'].value == true && fromDateJs != null) {
      if (fromDateJs > new Date()) {
        this.historyForm.controls['fromDatePicker'].setErrors({ 'incorrect': true });
        this.historyForm.controls['toDatePicker'].setErrors({ 'incorrect': true });
      } else {
        this.historyForm.controls['fromDatePicker'].setErrors(null);
        this.historyForm.controls['toDatePicker'].setErrors(null);
      }

    } else {

      if (fromDateJs != null && toDateJs != null) {
        if (fromDateJs > toDateJs) {
          this.historyForm.controls['fromDatePicker'].setErrors({ 'incorrect': true });
          this.historyForm.controls['toDatePicker'].setErrors({ 'incorrect': true });
        } else {
          this.historyForm.controls['fromDatePicker'].setErrors(null);
          this.historyForm.controls['toDatePicker'].setErrors(null);
        }
      }
    }
  }

  lastHistorySelected: any;
  onRowClicked(event: any) {

    if (this.lastHistorySelected == event.data.historyId) {
      if (this.viewResponse.nativeElement.style.height == '20px') {
        this.viewResponse.nativeElement.style.height = '270px';
      } else if (this.viewResponse.nativeElement.style.height == '270px') {
        this.viewResponse.nativeElement.style.height = '20px'
      }
    } else {
      this.lastHistorySelected = event.data.historyId;
      if (this.viewResponse.nativeElement.style.height == '20px') this.viewResponse.nativeElement.style.height = '270px';
    }
    if (event.data.status == 'Success') {
      this.historyForm.controls['response'].setValue(event.data.returnBody);
    } else {
      var response = '*** Error ***\n'
        + event.data.exceptionDetails
        + "\n\n***ReturnBody ***\n"
        + event.data.returnBody;

      this.historyForm.controls['response'].setValue(response);
    }
    this.ngAfterViewInit();
  }

  resizeEvent() {
    this.ngAfterViewInit();
  }

  ngOnDestroy() {
    this.formIsActive = false;
  }
}



@Directive({
  selector: 'textarea[resize]'
})
export class ResizableTextAreaDirective {
  @Output() resize = new EventEmitter();

  width: any;
  height: any;

  mouseMoveListener: any;

  @HostListener('mousedown', ['$event.target'])
  onMouseDown(el: any) {
    this.width = el.offsetWidth;
    this.height = el.offsetHeight;
    this.mouseMoveListener = this.renderer.listen('document', 'mousemove', () => {
      if (this.width !== el.offsetWidth || this.height !== el.offsetHeight) {
        this.resize.emit({ width: el.offsetWidth, height: el.offsetHeight });
      }
    });
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.ngOnDestroy();
  }

  constructor(private renderer: Renderer2) { }

  ngOnDestroy() {
    if (this.mouseMoveListener) {
      this.mouseMoveListener();
    }
  }
}
