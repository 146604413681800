import { Injectable } from '@angular/core';

import { NgbModalConfig,NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModaldialogboxComponent } from './modaldialogbox.component';

@Injectable()
export class ModalDialogService {

 
  constructor(config: NgbModalConfig,private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  
  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'lg'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ModaldialogboxComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
 

}
