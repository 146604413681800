import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JobListComponent } from './modules/job-list/job-list.component';
import { JobConfigureComponent } from './modules/job-configure/job-configure.component';
import { ScheduleComponent } from './modules/schedule/schedule.component';
import { HistoryListComponent, ResizableTextAreaDirective } from './modules/history-list/history-list.component';
import { FormsModule, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UITKInputModule } from '@uitk/angular';
import { UITKNavigationModule, UITKFooterModule, UITKCheckboxModule, UITKTextAreaModule } from '@uitk/angular';
import { UITKCardModule } from '@uitk/angular';
import { UITKTableModule } from '@uitk/angular';
import { UITKTooltipModule } from '@uitk/angular';
import { JobPromotionComponent } from './modules/job-promotion/job-promotion.component';
import { HelpComponent } from './help/help.component';
import { UITKDropdownModule } from '@uitk/angular';
import { HomeComponent } from './home/home.component';
import { UITKAutocompleteModule } from '@uitk/angular';
import { AgGridModule } from 'ag-grid-angular';
import { ConfigService } from './shared/services/config.service';
import { InterceptorService } from './shared/services/interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ButtonRendererComponent } from './shared/services/button-renderer.component';
import { ListButtonRendererComponent } from './shared/services/list-button-renderer.component'; 
import { UITKDialogModule } from '@uitk/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModaldialogboxComponent } from './modules/modaldialogbox/modaldialogbox.component';
import { ModalDialogService } from './modules/modaldialogbox/modal-dialog.service';
import { UITKDatePickerModule } from '@uitk/angular';
import { UITKRadioGroupModule } from '@uitk/angular';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentDateTimeAdapter, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { JobAlertsComponent } from './modules/job-alerts/job-alerts.component';
import { JobSummaryService } from './shared/services/job-summary.service';
import { NgHttpLoaderModule } from 'ng-http-loader';


@NgModule({
    declarations: [
        AppComponent,
        JobListComponent,
        JobConfigureComponent,
        ScheduleComponent,
        HistoryListComponent,
        JobPromotionComponent,
        HelpComponent,
        HomeComponent,
        ButtonRendererComponent,
        ModaldialogboxComponent,
        ResizableTextAreaDirective,
        ListButtonRendererComponent,
        JobAlertsComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        UITKInputModule,
        UITKNavigationModule,
        UITKFooterModule,
        UITKCardModule,
        UITKTableModule,
        UITKTooltipModule,
        UITKDropdownModule,
        UITKCheckboxModule,
        UITKTextAreaModule,
        UITKAutocompleteModule,
        UITKDialogModule,
        AgGridModule.withComponents([]),
        UITKDatePickerModule,
        UITKRadioGroupModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        BrowserAnimationsModule,
        NgHttpLoaderModule.forRoot()
    ],
    providers: [
        JobConfigureComponent,
        JobAlertsComponent,
        ModalDialogService,
        ConfigService,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        { provide: APP_INITIALIZER, useFactory: (config: ConfigService) => () => config.loadConfigurations(), deps: [ConfigService], multi: true },
        { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useValue: true } },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
