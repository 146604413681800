import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { UITKRadioGroupModule, UITKFieldsetModule, UITKCheckboxModule } from '@uitk/angular';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from 'src/app/shared/services/config.service';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { forEachChild } from 'typescript';
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})

export class ScheduleComponent implements OnInit {

  ScheduleForm = new UntypedFormGroup({
    OccursEveryRadio: new UntypedFormControl(),
    OccursEveryInput: new UntypedFormControl('', [Validators.pattern('^\\d*$')]),
    OccursEveryInputType: new UntypedFormControl(),
    OccursEverystartTime: new UntypedFormControl(''),
    OccursEveryendTime: new UntypedFormControl(),
    OccursAtRadio: new UntypedFormControl(),
    OccursAtInputStartTime: new UntypedFormControl(),
    OccursDailyRadio: new UntypedFormControl(),
    OccursDailyInputDays: new UntypedFormControl('', [Validators.pattern('^\\d*$')]),
    OccursWeeklyRadio: new UntypedFormControl(),
    OccursWeeklyCheckBoxSun: new UntypedFormControl(),
    OccursWeeklyCheckBoxMon: new UntypedFormControl(),
    OccursWeeklyCheckBoxTue: new UntypedFormControl(),
    OccursWeeklyCheckBoxWed: new UntypedFormControl(),
    OccursWeeklyCheckBoxThu: new UntypedFormControl(),
    OccursWeeklyCheckBoxFri: new UntypedFormControl(),
    OccursWeeklyCheckBoxSat: new UntypedFormControl(),
    RunEveryInputWeeks: new UntypedFormControl('', [Validators.pattern('^\\d*$')]),
    OccursMonthlyRadio: new UntypedFormControl(),
    RunEveryInputMonths: new UntypedFormControl('', [Validators.pattern('^\\d*$')]),
    RunOnTheRadio: new UntypedFormControl(),
    RunOnTheInputSecond: new UntypedFormControl(),
    RunOnTheInputWeekEndDay: new UntypedFormControl(),
    RunOnThe2Radio: new UntypedFormControl(),
    RunOnTheInputDayOfTheMonths: new UntypedFormControl('', [Validators.pattern('^\\d*$')]),
    NoEndDateCheckBox: new UntypedFormControl(),
    EndDateInput: new UntypedFormControl(),
    EnabledCheckBox: new UntypedFormControl(),
    radioGroup: new UntypedFormControl(''),
    radioGroup1: new UntypedFormControl(''),
    intervel: new UntypedFormControl(''),
    MonthlyFirstintervel: new UntypedFormControl(''),
    MonthlySecondintervel: new UntypedFormControl(''),
  });

  frequencyData: any;
  occurEveryPeriodData: any;
  //Variables for disabling elements
  disableOccursAtInputStartTime: boolean = true;
  disableOccursEveryControls: boolean = true;
  disableOccursDailyRadioControls: boolean = true;
  disableOccursWeeklyRadioControls: boolean = true;
  disableOccursMonthlyRadioControls: boolean = true;
  disableRunOnTheRadioControls: boolean = true;
  disableRunOnThe2RadioControls: boolean = true;
  //disableRunOnTheRadioButtons: boolean = true;
  disableEndDateInput: boolean = true;
  scheduleTypes = [
    { id: 0, name: "Sun", value: "runSunday", checked: false },
    { id: 1, name: "Mon", value: "runMonday", checked: false },
    { id: 2, name: "Tue", value: "runTueday", checked: false },
    { id: 3, name: "Wed", value: "runWednesday", checked: false },
    { id: 4, name: "Thu", value: "runThursday", checked: false },
    { id: 5, name: "Fri", value: "runFriday", checked: false },
    { id: 6, name: "Sat", value: "runSaturday", checked: false },
  ];
  //radioGroups = [
  //  { control: "OccursDailyRadio", value: "Daily" },
  //  { control: "OccursWeeklyRadio", value: "Weekly" },
  //  { control: "OccursMonthlyRadio", value: "Monthly" },
  //];

  OccursEveryRadioitems = [
    {
      label: 'Occurs Every',
      value: 'myValue1',
    },


  ];
  RunOnTheRadioitems = [
    {
      label: 'Run on the',
      value: 'myValue1',
    },


  ];
  RunOnThe2Radioitems = [
    {
      label: 'Run on the',
      value: 'myValue1',
    },


  ];
  OccursAtRadioitems = [
    {
      label: 'Occurs At',
      value: 'myValue1',
    },


  ];
  OccursDailyRadioitems = [
    {
      label: 'Daily',
      value: 'myValue1',
    },


  ];
  OccursWeeklyRadioitems = [
    {
      label: 'Weekly',
      value: 'myValue1',
    },


  ];
  OccursMonthlyRadioitems = [
    {
      label: 'Monthly',
      value: 'myValue1',
    },


  ];
  intervels: any = [
    { label: 'second(s)', value: 'second(s)' },
    { label: 'minute(s)', value: 'minute(s)' },
    { label: 'hour(s)', value: 'hour(s)' },

  ];
  MonthlyFirstintervels = [
    { label: 'first', value: '1' },
    { label: 'second', value: '2' },
    { label: 'third', value: '3' },
    { label: 'fourth', value: '4' },
    { label: 'last', value: '5' },

  ];

  MonthlySecondintervels = [
    { label: 'day', value: '1' },
    { label: 'weekday', value: '2' },
    { label: 'weekendday', value: '3' },
    { label: 'Sunday', value: '4' },
    { label: 'Monday', value: '5' },
    { label: 'Tuesday', value: '6' },
    { label: 'Wednesday', value: '7' },
    { label: 'Thursday', value: '8' },
    { label: 'Friday', value: '9' },
    { label: 'Saturday', value: '10' },

  ];

  constructor(private jobSummaryService: JobSummaryService,
    private authorizationService: AuthorizationService,
    protected httpClient: HttpClient,
    private configService: ConfigService,

  ) { }

  jobListSelectedFirstTime = true;
  scheduleValidateFirstTime = true;
  scheduleClearDataFirstTime = true;
  scheduleDirtyFirstTime = true;
  ngOnInit() {
    this.jobSummaryService.jobListSelected$.subscribe((x: any) => {
      if (this.jobListSelectedFirstTime == true)
        this.jobListSelectedFirstTime = false;
      else
        this.getRecords();
    });

    this.jobSummaryService.scheduleValidate$.subscribe((x: any) => {
      if (this.scheduleValidateFirstTime == true)
        this.scheduleValidateFirstTime = false;
      else
        this.validateRecords();
    });

    this.jobSummaryService.scheduleClearData$.subscribe((x: any) => {
      if (this.scheduleClearDataFirstTime == true)
        this.scheduleClearDataFirstTime = false;
      else
        this.clearForm();
    });

    this.jobSummaryService.scheduleDirty$.subscribe((x: any) => {
      if (this.scheduleDirtyFirstTime == true)
        this.scheduleDirtyFirstTime = false;
      else
        this.checkDirty();
    });


    this.clearForm();
    this.getRecords();

  }



  clearForm() {
    Object.keys(this.ScheduleForm.controls).forEach(key => {
      this.ScheduleForm.controls[key].setValue(null);
      this.ScheduleForm.controls[key].markAsPristine();
    });

    //Set default radial values
    this.onOccursRadio('OccursEveryRadio');
    this.onRunRadio('RunOnTheRadio');
    this.onschedRadio('OccursDailyRadio');

    this.ScheduleForm.controls["NoEndDateCheckBox"].setValue(true);
    this.ScheduleForm.controls["NoEndDateCheckBox"].markAsPristine();

    this.ScheduleForm.controls["OccursEverystartTime"].setValue("1900-01-01T00:00:00.00");
    this.ScheduleForm.controls["OccursEverystartTime"].markAsPristine();
    this.ScheduleForm.controls["OccursEveryendTime"].setValue("1900-01-01T23:59:59.99")
    this.ScheduleForm.controls["OccursEveryendTime"].markAsPristine();

    this.disableEndDateInput = true;
  }


  isDirty: boolean = false
  async checkDirty() {
    this.isDirty = false;

    //Row1
    if (this.ScheduleForm.controls["OccursEveryRadio"].value != null) {
      if (this.ScheduleForm.controls["OccursEveryInput"].dirty == true) this.isDirty = true;
      if (this.ScheduleForm.controls["intervel"].dirty == true) this.isDirty = true;

      if (this.ScheduleForm.controls["OccursEverystartTime"].value != null && this.ScheduleForm.controls["OccursEverystartTime"].value != '') {
        if (this.ScheduleForm.controls["OccursEverystartTime"].dirty == true) this.isDirty = true;
      }

      if (this.ScheduleForm.controls["OccursEveryendTime"].value != null && this.ScheduleForm.controls["OccursEveryendTime"].value != '') {
        if (this.ScheduleForm.controls["OccursEveryendTime"].dirty == true) this.isDirty = true;
      }
    }
    //Row2
    if (this.ScheduleForm.controls["OccursAtRadio"].value != null) {
      if (this.ScheduleForm.controls["OccursAtInputStartTime"].dirty) this.isDirty = true;
    }

    //Row3
    if (this.ScheduleForm.controls["OccursDailyRadio"].value != null) {
      if (this.ScheduleForm.controls["OccursDailyInputDays"].dirty) this.isDirty = true;
    }

    //Row4
    if (this.ScheduleForm.controls["OccursWeeklyRadio"].value != null) {
      if (this.ScheduleForm.controls["RunEveryInputWeeks"].dirty) this.isDirty = true;
    }

    //Row6, 7 & 8
    if (this.ScheduleForm.controls["OccursMonthlyRadio"].value != null) {
      if (this.ScheduleForm.controls["RunEveryInputMonths"].dirty) this.isDirty = true;

      if (this.ScheduleForm.controls["RunOnTheRadio"].value != null) {
        if (this.ScheduleForm.controls["MonthlyFirstintervel"].dirty) this.isDirty = true;
        if (this.ScheduleForm.controls["MonthlySecondintervel"].dirty) this.isDirty = true;
      }

      if (this.ScheduleForm.controls["RunOnThe2Radio"].value != null) {
        if (this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].dirty) this.isDirty = true;
      }
    }

    //Row 9
    if (this.ScheduleForm.controls["NoEndDateCheckBox"].dirty) this.isDirty = true;
    if (this.ScheduleForm.controls["EnabledCheckBox"].dirty) this.isDirty = true;

    //Set the response
    this.jobSummaryService.scheduleDirty = this.isDirty;
  }

  validRecords: boolean = true;
  validateRecords() {

    this.validRecords = true;

    //Clear requireds before validate
    Object.keys(this.ScheduleForm.controls).forEach(key => {
      this.ScheduleForm.controls[key].setErrors(null);
    });

    //Row 1
    if (this.ScheduleForm.controls["OccursEveryRadio"].value != null) {
      //Check Required
      if (this.ScheduleForm.controls["OccursEveryInput"].value == null || this.ScheduleForm.controls["OccursEveryInput"].value == '') {
        this.ScheduleForm.controls["OccursEveryInput"].setErrors({ 'required': 'true' }); this.validRecords = false;
        if (this.ScheduleForm.controls["OccursEverystartTime"].valid == false) this.validRecords = false;

      }
      //Check Valid
      if (this.ScheduleForm.controls["OccursEveryInput"].valid == false) this.validRecords = false;

      //Check required
      this.ScheduleForm.controls["intervel"].setErrors(null);
      if (this.ScheduleForm.controls["intervel"].value == null || this.ScheduleForm.controls["intervel"].value.label == 'Select') {
        this.ScheduleForm.controls["intervel"].setErrors({ 'required': 'true' });
        this.validRecords = false;

      }
      //Check valid
      if (this.ScheduleForm.controls["intervel"].valid == false) this.validRecords = false;

      //If StartTime set then EndTime must be set
      this.ScheduleForm.controls["OccursEverystartTime"].setErrors(null);
      this.ScheduleForm.controls["OccursEveryendTime"].setErrors(null);

      if ((this.ScheduleForm.controls["OccursEverystartTime"].value == null || this.ScheduleForm.controls["OccursEverystartTime"].value == '')) {
        this.ScheduleForm.controls["OccursEverystartTime"].setErrors({ 'required': 'true' });
        this.validRecords = false;

      }
      if ((this.ScheduleForm.controls["OccursEveryendTime"].value == null || this.ScheduleForm.controls["OccursEveryendTime"].value == '')) {
        this.ScheduleForm.controls["OccursEveryendTime"].setErrors({ 'required': 'true' });
        this.validRecords = false;
      }

      if ((this.ScheduleForm.controls["OccursEverystartTime"].value != null && this.ScheduleForm.controls["OccursEverystartTime"].value != '')
        && (this.ScheduleForm.controls["OccursEveryendTime"].value != null && this.ScheduleForm.controls["OccursEveryendTime"].value != '')
        && this.ScheduleForm.controls["OccursEveryendTime"].value < this.ScheduleForm.controls["OccursEverystartTime"].value) {

        this.ScheduleForm.controls["OccursEveryendTime"].setErrors({ 'invalid': 'true' });
        this.ScheduleForm.controls["OccursEverystartTime"].setErrors({ 'invalid': 'true' });
        this.validRecords = false;
      }

      //valid value check 
      if (this.ScheduleForm.controls["OccursEverystartTime"].value != null && this.ScheduleForm.controls["OccursEverystartTime"].value != '') {
        if (this.ScheduleForm.controls["OccursEverystartTime"].valid == false) this.validRecords = false;
      }

      if (this.ScheduleForm.controls["OccursEveryendTime"].value != null && this.ScheduleForm.controls["OccursEveryendTime"].value != '') {
        if (this.ScheduleForm.controls["OccursEveryendTime"].valid == false) this.validRecords = false;
      }

    }

    //Row 2
    if (this.ScheduleForm.controls["OccursAtRadio"].value != null) {
      if (this.ScheduleForm.controls["OccursAtInputStartTime"].value == null || this.ScheduleForm.controls["OccursAtInputStartTime"].value == '') {
        this.ScheduleForm.controls["OccursAtInputStartTime"].setErrors({ 'required': 'true' });
        if (this.ScheduleForm.controls["OccursAtInputStartTime"].valid == false) this.validRecords = false;
      }
    }

    //Row3
    if (this.ScheduleForm.controls["OccursDailyRadio"].value != null) {
      if (this.ScheduleForm.controls["OccursDailyInputDays"].value == null || this.ScheduleForm.controls["OccursDailyInputDays"].value == '') {
        this.ScheduleForm.controls["OccursDailyInputDays"].setErrors({ 'required': 'true' });
        if (this.ScheduleForm.controls["OccursDailyInputDays"].valid == false) this.validRecords = false;
      }
    }

    //Row4
    if (this.ScheduleForm.controls["OccursWeeklyRadio"].value != null) {
      if (this.ScheduleForm.controls["RunEveryInputWeeks"].value == null || this.ScheduleForm.controls["RunEveryInputWeeks"].value == '') {
        this.ScheduleForm.controls["RunEveryInputWeeks"].setErrors({ 'required': 'true' });
        if (this.ScheduleForm.controls["RunEveryInputWeeks"].valid == false) this.validRecords = false;
      }
    }

    //Row6
    if (this.ScheduleForm.controls["OccursMonthlyRadio"].value != null) {
      if (this.ScheduleForm.controls["RunEveryInputMonths"].value == null || this.ScheduleForm.controls["RunEveryInputMonths"].value == '') {
        this.ScheduleForm.controls["RunEveryInputMonths"].setErrors({ 'required': 'true' });
        if (this.ScheduleForm.controls["RunEveryInputMonths"].valid == false) this.validRecords = false;
      }
      if (this.ScheduleForm.controls["RunOnTheRadio"].value != null) {
        if (this.ScheduleForm.controls["MonthlyFirstintervel"].value == 'Select' || this.ScheduleForm.controls["MonthlyFirstintervel"].value == null) {
          this.ScheduleForm.controls["MonthlyFirstintervel"].setErrors({ 'required': 'true' });
          if (this.ScheduleForm.controls["MonthlyFirstintervel"].valid == false) this.validRecords = false;
        }
        if (this.ScheduleForm.controls["MonthlySecondintervel"].value == 'Select' || this.ScheduleForm.controls["MonthlySecondintervel"].value == null) {
          this.ScheduleForm.controls["MonthlySecondintervel"].setErrors({ 'required': 'true' });
          if (this.ScheduleForm.controls["MonthlySecondintervel"].valid == false) this.validRecords = false;
        }
      }
      if (this.ScheduleForm.controls["RunOnThe2Radio"].value != null) {
        if (this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].value == null || this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].value == '') {
          this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].setErrors({ 'required': 'true' });
          if (this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].valid == false) this.validRecords = false;
        }
      }

    }



    //assemble the json
    this.jobSummaryService.schduleData = {
      absoluteDay: this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].value == null ? 0 : this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].value,
      endDate: this.ScheduleForm.controls["EndDateInput"].value,
      offset: new Date().getTimezoneOffset(),

      startTime: this.ConvertDate(this.ScheduleForm.controls["OccursEverystartTime"].value),
      endTime: this.ConvertDate(this.ScheduleForm.controls["OccursEveryendTime"].value),

      frequency: this.frequencyData,
      isEnabled: this.ScheduleForm.controls["EnabledCheckBox"].value == null ? false : this.ScheduleForm.controls["EnabledCheckBox"].value,
      //lastRunDate: null,// this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].value,//not there
      occurEveryPeriod: this.ScheduleForm.controls["OccursEveryInput"].value == null ? 0 : this.ScheduleForm.controls["OccursEveryInput"].value,
      occurEveryUnit: this.ScheduleForm.controls["intervel"].value?.value == null ? "hour(s)" : this.ScheduleForm.controls["intervel"].value?.value,

      occurTime: this.ConvertDate(this.ScheduleForm.controls["OccursAtInputStartTime"].value),

      recurEvery: (this.ScheduleForm.controls["OccursMonthlyRadio"].value != null) ?
        this.ScheduleForm.controls["RunEveryInputMonths"].value == null ? 0 : this.ScheduleForm.controls["RunEveryInputMonths"].value :
        (this.ScheduleForm.controls["OccursWeeklyRadio"].value != null) ?
          this.ScheduleForm.controls["RunEveryInputWeeks"].value == null ? 0 : this.ScheduleForm.controls["RunEveryInputWeeks"].value :
          this.ScheduleForm.controls["OccursDailyInputDays"].value == null ? 0 : this.ScheduleForm.controls["OccursDailyInputDays"].value,

      relativeDayPlace: this.ScheduleForm.controls["MonthlyFirstintervel"].value == null ? "" : this.ScheduleForm.controls["MonthlyFirstintervel"].value.label,
      relativeDayType: this.ScheduleForm.controls["MonthlySecondintervel"].value == null ? "" : this.ScheduleForm.controls["MonthlySecondintervel"].value.label,
      runFriday: this.ScheduleForm.controls["OccursWeeklyCheckBoxFri"].value == null ? false : this.ScheduleForm.controls["OccursWeeklyCheckBoxFri"].value,
      runMonday: this.ScheduleForm.controls["OccursWeeklyCheckBoxMon"].value == null ? false : this.ScheduleForm.controls["OccursWeeklyCheckBoxMon"].value,
      runSaturday: this.ScheduleForm.controls["OccursWeeklyCheckBoxSat"].value == null ? false : this.ScheduleForm.controls["OccursWeeklyCheckBoxSat"].value,
      runSunday: this.ScheduleForm.controls["OccursWeeklyCheckBoxSun"].value == null ? false : this.ScheduleForm.controls["OccursWeeklyCheckBoxSun"].value,
      runThursday: this.ScheduleForm.controls["OccursWeeklyCheckBoxThu"].value == null ? false : this.ScheduleForm.controls["OccursWeeklyCheckBoxThu"].value,
      runTuesday: this.ScheduleForm.controls["OccursWeeklyCheckBoxTue"].value == null ? false : this.ScheduleForm.controls["OccursWeeklyCheckBoxTue"].value,
      runWednesday: this.ScheduleForm.controls["OccursWeeklyCheckBoxWed"].value == null ? false : this.ScheduleForm.controls["OccursWeeklyCheckBoxWed"].value,
      scheduleId: this.jobSummaryService.selectedRowData?.schedule?.scheduleId == null ? 0 : this.jobSummaryService.selectedRowData?.schedule?.scheduleId,
      //updateDate: null,//this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].value//not there
    }
    // need to finalize variale this.jobSummaryService.

    //Set the service values
    this.jobSummaryService.scheduleValid = this.validRecords;

  }

  ConvertDate(dateIn: any): any {
    var dateY = null;
    if (dateIn != null) {
      var dateX = new Date(dateIn);
      dateY = '1900-01-01T'
        + (dateX.getHours().toString().length == 1 ? "0" + dateX.getHours().toString() : dateX.getHours().toString()) + ':'
        + (dateX.getMinutes().toString().length == 1 ? "0" + dateX.getMinutes().toString() : dateX.getMinutes().toString()) + ':'
        + (dateX.getSeconds().toString().length == 1 ? "0" + dateX.getSeconds().toString() : dateX.getSeconds().toString()) + '.000';
    }
    return dateY;
  }

  occurDaily: any;
  getRecords() {
    var jobId = this.jobSummaryService.selectedRowId;
    if (jobId == null || jobId == undefined) return;

    this.clearForm();

    var res = this.jobSummaryService.selectedRowData;

    this.ScheduleForm.controls["NoEndDateCheckBox"].setValue(null);
    if (res.schedule != null) {
      //Set Radio values first
      this.onOccursRadio(null); //Clear the Occurs values
      if (res.schedule.occurEveryPeriod != 0) this.ScheduleForm.controls["OccursEveryRadio"].setValue(this.OccursEveryRadioitems[0]);
      //if (res.schedule.occurTime != null) this.ScheduleForm.controls["OccursAtRadio"].setValue(this.OccursAtRadioitems[0]);
      if (res.schedule.occurTime != null) this.onOccursRadio("OccursAtRadio");

      this.ScheduleForm.controls["OccursEveryInput"].setValue(res.schedule.occurEveryPeriod);//occurEveryUnit

      //this.ScheduleForm.controls['intervel'].setValue(this.intervels[res.schdule.occurEveryUnit])
      this.intervels.forEach((x: any) => {
        if (x.value == res.schedule.occurEveryUnit) {
          this.ScheduleForm.controls["intervel"].setValue(x);
        }
      });

      //months1st dropdown
      this.MonthlyFirstintervels.forEach(x => {
        if (x.label == res.schedule.relativeDayPlace) {
          this.ScheduleForm.controls["MonthlyFirstintervel"].setValue(x);
        }
      });

      //months 2nd dropdown
      this.MonthlySecondintervels.forEach(x => {
        if (x.label == res.schedule.relativeDayType) {
          this.ScheduleForm.controls["MonthlySecondintervel"].setValue(x);
        }
      });

      //Run on the radials
      this.onRunRadio(null);
      //if (res.schedule.relativeDayPlace != "" && res.schedule.relativeDayType != "" && res.schedule.relativeDayPlace != "string"
      //  && res.schedule.relativeDayType != "string") {
      //  this.onRunRadio("RunOnTheRadio");
      //} else
      if (res.schedule.absoluteDay != 0) {
        this.onRunRadio("RunOnThe2Radio");
        this.disableRunOnTheRadioControls = true;
      } else {
        this.onRunRadio("RunOnTheRadio");
        this.disableRunOnTheRadioControls = false;
      }


      if (res.schedule.frequency == "Daily") this.onschedRadio("OccursDailyRadio");
      if (res.schedule.frequency == "Weekly") this.onschedRadio("OccursWeeklyRadio");
      if (res.schedule.frequency == "Monthly") this.onschedRadio("OccursMonthlyRadio");
      this.ScheduleForm.controls["OccursEverystartTime"].setValue(res.schedule.startTime);
      this.ScheduleForm.controls["OccursEveryendTime"].setValue(res.schedule.endTime);
      this.ScheduleForm.controls["OccursAtInputStartTime"].setValue(res.schedule.occurTime);
      this.ScheduleForm.controls["OccursDailyInputDays"].setValue(res.schedule.recurEvery);
      this.ScheduleForm.controls["RunEveryInputWeeks"].setValue(res.schedule.recurEvery);
      this.ScheduleForm.controls["RunEveryInputMonths"].setValue(res.schedule.recurEvery);
      this.ScheduleForm.controls["RunOnTheInputDayOfTheMonths"].setValue(res.schedule.absoluteDay);
      this.ScheduleForm.controls["OccursWeeklyCheckBoxSun"].setValue(res.schedule.runSunday);
      this.ScheduleForm.controls["OccursWeeklyCheckBoxMon"].setValue(res.schedule.runMonday);
      this.ScheduleForm.controls["OccursWeeklyCheckBoxTue"].setValue(res.schedule.runTuesday);
      this.ScheduleForm.controls["OccursWeeklyCheckBoxWed"].setValue(res.schedule.runWednesday);
      this.ScheduleForm.controls["OccursWeeklyCheckBoxThu"].setValue(res.schedule.runThursday);
      this.ScheduleForm.controls["OccursWeeklyCheckBoxFri"].setValue(res.schedule.runFriday);
      this.ScheduleForm.controls["OccursWeeklyCheckBoxSat"].setValue(res.schedule.runSaturday);
      this.ScheduleForm.controls["EnabledCheckBox"].setValue(res.schedule.isEnabled);
      this.ScheduleForm.controls["EndDateInput"].setValue(res.schedule.endDate);

      if (res.schedule.endDate == null) {
        this.ScheduleForm.controls["NoEndDateCheckBox"].setValue(true);
        this.disableEndDateInput = true;
      } else {
        this.disableEndDateInput = false;
      }

      //Mark as prestine
      Object.keys(this.ScheduleForm.controls).forEach(key => {
        this.ScheduleForm.controls[key].markAsPristine();
      });


    }
  }

  onClickNoEndDateCheckBox(event: any) {
    if (this.ScheduleForm.controls["NoEndDateCheckBox"].value != true) {
      this.ScheduleForm.controls["EndDateInput"].setValue(new Date());
      this.disableEndDateInput = false;
    }
    if (this.ScheduleForm.controls["NoEndDateCheckBox"].value == true) {
      this.ScheduleForm.controls["EndDateInput"].setValue(null);
      this.disableEndDateInput = true;
    }
  }

  onClickEndDateInput(event: any) {
    if (this.ScheduleForm.controls["EndDateInput"].value == null) {
      this.ScheduleForm.controls["NoEndDateCheckBox"].setValue(true);
    }
    if (this.ScheduleForm.controls["EndDateInput"].value != null) {
      this.ScheduleForm.controls["NoEndDateCheckBox"].setValue(false);
    }
  }



  onOccursRadio(event: any) {
    const occursRadial = ['OccursEveryRadio', 'OccursAtRadio']
    //const controlsUnder = ['OccursEveryInput', 'intervel', 'OccursEverystartTime', 'OccursEveryendTime', 'OccursAtInputStartTime'];

    let control: any = null;
    let fromInit: boolean = false;
    if (event?.srcElement == undefined) {
      control = event;
      fromInit = true;
    } else {
      control = event.srcElement.name;
    }

    for (let i = 0; i < occursRadial.length; i++) {
      this.ScheduleForm.controls[occursRadial[i]].setValue(null);
    }
    if (control == 'OccursEveryRadio') this.ScheduleForm.controls[control].setValue(this.OccursEveryRadioitems[0]);
    if (control == 'OccursAtRadio') this.ScheduleForm.controls[control].setValue(this.OccursAtRadioitems[0]);

    //Disabling code for Occurs elements
    if (this.ScheduleForm.controls["OccursEveryRadio"].value != null) {
      this.disableOccursAtInputStartTime = true;
      this.disableOccursEveryControls = false;
      //Clear other values
      this.ScheduleForm.controls['OccursAtInputStartTime'].setValue(null);
    }

    if (this.ScheduleForm.controls["OccursAtRadio"].value != null) {
      this.disableOccursAtInputStartTime = false;
      this.disableOccursEveryControls = true;
      //Clear other values
      setTimeout(() => {
        this.ScheduleForm.controls["OccursEverystartTime"].setValue("1900-01-01T00:00:00.00");
        this.ScheduleForm.controls["OccursEveryendTime"].setValue("1900-01-01T23:59:59.99")
        this.ScheduleForm.controls['OccursEveryInput'].setValue(null);
        this.ScheduleForm.controls['intervel'].setValue(null);
      }, 0);
    }

  }

  onschedRadio(event: any) {
    const schedRadio = ['OccursDailyRadio', 'OccursWeeklyRadio', 'OccursMonthlyRadio']
    //const controlsUnder = ['OccursDailyInputDays', 'RunEveryInputWeeks', 'OccursWeeklyCheckBoxSun', 'OccursWeeklyCheckBoxMon',
    //  'OccursWeeklyCheckBoxTue', 'OccursWeeklyCheckBoxWed', 'OccursWeeklyCheckBoxThu', 'OccursWeeklyCheckBoxFri',
    //  'OccursWeeklyCheckBoxSat', 'RunEveryInputMonths', 'MonthlyFirstintervel', 'MonthlySecondintervel', 'RunOnTheInputDayOfTheMonths'];

    let control: any = null;
    let fromInit: boolean = false;
    if (event?.srcElement == undefined) {
      control = event;
      fromInit = true;
    } else {
      control = event.srcElement.name;
    }

    for (let i = 0; i < schedRadio.length; i++) {
      this.ScheduleForm.controls[schedRadio[i]].setValue(null);
    }
    if (control == 'OccursDailyRadio') this.ScheduleForm.controls[control].setValue(this.OccursDailyRadioitems[0]);
    if (control == 'OccursWeeklyRadio') this.ScheduleForm.controls[control].setValue(this.OccursWeeklyRadioitems[0]);
    if (control == 'OccursMonthlyRadio') this.ScheduleForm.controls[control].setValue(this.OccursMonthlyRadioitems[0]);

    //Disabling code for OccursFrequency daily,weekly,monthly elements
    if (this.ScheduleForm.controls["OccursDailyRadio"].value != null) {
      this.frequencyData = "Daily";
      this.disableOccursDailyRadioControls = false;
      this.disableOccursWeeklyRadioControls = true;
      this.disableOccursMonthlyRadioControls = true;
      //Clear other values
      this.ScheduleForm.controls['RunEveryInputMonths'].setValue("");
      this.ScheduleForm.controls['RunEveryInputWeeks'].setValue("");
    }

    if (this.ScheduleForm.controls["OccursWeeklyRadio"].value != null) {
      this.frequencyData = "Weekly";
      this.disableOccursDailyRadioControls = true;
      this.disableOccursWeeklyRadioControls = false;
      this.disableOccursMonthlyRadioControls = true;
      //Clear other values
      this.ScheduleForm.controls['RunEveryInputMonths'].setValue("");
      this.ScheduleForm.controls['OccursDailyInputDays'].setValue("");
    }

    if (this.ScheduleForm.controls["OccursMonthlyRadio"].value != null) {
      this.frequencyData = "Monthly";
      this.disableOccursDailyRadioControls = true;
      this.disableOccursWeeklyRadioControls = true;
      this.disableOccursMonthlyRadioControls = false;
      //Clear other values
      this.ScheduleForm.controls['RunEveryInputWeeks'].setValue(null);
      this.ScheduleForm.controls['OccursDailyInputDays'].setValue(null);
    }

  }

  onRunRadio(event: any) {

    const runRadio = ['RunOnTheRadio', 'RunOnThe2Radio']
    //const runcontrolsUnder = ['MonthlyFirstintervel', 'MonthlySecondintervel', 'RunOnTheInputDayOfTheMonths'];

    let control: any = null;
    let fromInit: boolean = false;
    if (event?.srcElement == undefined) {
      control = event;
      fromInit = true;
    } else {
      control = event.srcElement.name;
    }

    for (let i = 0; i < runRadio.length; i++) {
      this.ScheduleForm.controls[runRadio[i]].setValue(null);
    }
    if (control == 'RunOnTheRadio') this.ScheduleForm.controls[control].setValue(this.RunOnTheRadioitems[0]);
    if (control == 'RunOnThe2Radio') this.ScheduleForm.controls[control].setValue(this.RunOnThe2Radioitems[0]);

    //Disabling code for Run on the elements
    if (this.ScheduleForm.controls["RunOnTheRadio"].value != null) {
      this.disableRunOnThe2RadioControls = true;
      this.disableRunOnTheRadioControls = false;
      //Clear Data
      this.ScheduleForm.controls['RunOnTheInputDayOfTheMonths'].setValue(null);

    }

    if (this.ScheduleForm.controls["RunOnThe2Radio"].value != null) {
      this.disableRunOnThe2RadioControls = false;
      this.disableRunOnTheRadioControls = true;
      //Clear Data
      this.ScheduleForm.controls['MonthlyFirstintervel'].setValue(null);
      this.ScheduleForm.controls['MonthlySecondintervel'].setValue(null);
    }

  }

}
