<html>

<head>
  <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
  <meta name=Generator content="Microsoft Word 15 (filtered)">
  <style>
    <!--
    /* Font Definitions */
    @font-face {
      font-family: "Cambria Math";
      panose-1: 2 4 5 3 5 4 6 3 2 4;
    }

    @font-face {
      font-family: Calibri;
      panose-1: 2 15 5 2 2 2 4 3 2 4;
    }

    @font-face {
      font-family: Cambria;
      panose-1: 2 4 5 3 5 4 6 3 2 4;
    }
    /* Style Definitions */
    p.MsoNormal, li.MsoNormal, div.MsoNormal {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 10.0pt;
      margin-left: 0in;
      line-height: 115%;
      font-size: 11.0pt;
      font-family: "Calibri",sans-serif;
    }

    h1 {
      mso-style-link: "Heading 1 Char";
      margin-top: 12.0pt;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0in;
      margin-bottom: .0001pt;
      line-height: 115%;
      page-break-after: avoid;
      font-size: 16.0pt;
      font-family: "Cambria",serif;
      color: #365F91;
      font-weight: normal;
    }

    h2 {
      mso-style-link: "Heading 2 Char";
      margin-top: 2.0pt;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0in;
      margin-bottom: .0001pt;
      line-height: 115%;
      page-break-after: avoid;
      font-size: 13.0pt;
      font-family: "Cambria",serif;
      color: #365F91;
      font-weight: normal;
    }

    h3 {
      mso-style-link: "Heading 3 Char";
      margin-top: 2.0pt;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0in;
      margin-bottom: .0001pt;
      line-height: 115%;
      page-break-after: avoid;
      font-size: 12.0pt;
      font-family: "Cambria",serif;
      color: #243F60;
      font-weight: normal;
    }

    h4 {
      mso-style-link: "Heading 4 Char";
      margin-top: 2.0pt;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0in;
      margin-bottom: .0001pt;
      line-height: 115%;
      page-break-after: avoid;
      font-size: 11.0pt;
      font-family: "Cambria",serif;
      color: #365F91;
      font-weight: normal;
      font-style: italic;
    }

    p.MsoTitle, li.MsoTitle, div.MsoTitle {
      mso-style-link: "Title Char";
      margin: 0in;
      margin-bottom: .0001pt;
      font-size: 28.0pt;
      font-family: "Cambria",serif;
      letter-spacing: -.5pt;
    }

    p.MsoTitleCxSpFirst, li.MsoTitleCxSpFirst, div.MsoTitleCxSpFirst {
      mso-style-link: "Title Char";
      margin: 0in;
      margin-bottom: .0001pt;
      font-size: 28.0pt;
      font-family: "Cambria",serif;
      letter-spacing: -.5pt;
    }

    p.MsoTitleCxSpMiddle, li.MsoTitleCxSpMiddle, div.MsoTitleCxSpMiddle {
      mso-style-link: "Title Char";
      margin: 0in;
      margin-bottom: .0001pt;
      font-size: 28.0pt;
      font-family: "Cambria",serif;
      letter-spacing: -.5pt;
    }

    p.MsoTitleCxSpLast, li.MsoTitleCxSpLast, div.MsoTitleCxSpLast {
      mso-style-link: "Title Char";
      margin: 0in;
      margin-bottom: .0001pt;
      font-size: 28.0pt;
      font-family: "Cambria",serif;
      letter-spacing: -.5pt;
    }

    span.TitleChar {
      mso-style-name: "Title Char";
      mso-style-link: Title;
      font-family: "Cambria",serif;
      letter-spacing: -.5pt;
    }

    span.Heading1Char {
      mso-style-name: "Heading 1 Char";
      mso-style-link: "Heading 1";
      font-family: "Cambria",serif;
      color: #365F91;
    }

    span.Heading2Char {
      mso-style-name: "Heading 2 Char";
      mso-style-link: "Heading 2";
      font-family: "Cambria",serif;
      color: #365F91;
    }

    span.Heading3Char {
      mso-style-name: "Heading 3 Char";
      mso-style-link: "Heading 3";
      font-family: "Cambria",serif;
      color: #243F60;
    }

    span.Heading4Char {
      mso-style-name: "Heading 4 Char";
      mso-style-link: "Heading 4";
      font-family: "Cambria",serif;
      color: #365F91;
      font-style: italic;
    }

    .MsoChpDefault {
      font-family: "Calibri",sans-serif;
    }

    .MsoPapDefault {
      margin-bottom: 10.0pt;
      line-height: 115%;
    }

    @page WordSection1 {
      size: 8.5in 11.0in;
      margin: 1.0in 1.0in 1.0in 1.0in;
    }

    div.WordSection1 {
      page: WordSection1;
    }
    -->
  </style>

</head>

<body lang=EN-US>

  <div class=WordSection1>

    <p class=MsoTitle align=center style='text-align:center'><u>Service Scheduler</u></p>

    <h1>Purpose:</h1>

    <p class=MsoNormal>
      The Service Scheduler is a standalone application that is
      configurable to fun multiple job type activities at varying frequencies
      determined by how the job is configured. 
    </p>

    <h1>Job Types</h1>

    <h2 style='margin-left:.5in'>WCF service</h2>

    <p class=MsoNormal>
      This job type allows the service to call Windows
      Communication Foundation services identifying the URL, Contract and Method of
      the call.
    </p>

    <h2 style='margin-left:.5in'>Rest Service</h2>

    <p class=MsoNormal>
      This job type allows the service to make Representational
      State Transfer (also known as RESTful API) calls.  The system is set up to
      primarily send Post requests, but Get, Put, Post and Delete call options are
      supported as well as http header keys.
    </p>

    <h2 style='margin-left:.5in'>SQL Database</h2>

    <p class=MsoNormal>
      This job type supports Structured Query Language calls to a
      database that is authorized by the Service Scheduler Windows (NT) service app
      pool user.  User must provide Server, Database and command to run/execute. 
    </p>

    <h2 style='margin-left:.5in'>PowerShell</h2>

    <p class=MsoNormal>
      This job type allows the user to configure the process to
      call a PowerShell command, or list a file containing PowerShell commands to be
      run.
    </p>

    <h1>The Interface</h1>

    <h2 style='margin-left:.5in'>The Header</h2>

    <p class=MsoNormal>
      <img width=576 height=253 id="Picture 3"
           src="assets/Images/Help_files/image001.png">
    </p>

    <h2 style='margin-left:.5in'>Job Listing</h2>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>
      <img width=952 height=268 id="Picture 2"
           src="assets/Images/Help_files/image002.png">
    </p>

    <p class=MsoNormal>Column filtering can be made on the following:</p>

    <p class=MsoNormal>
      <img width=185 height=109 id="Picture 1"
           src="assets/Images/Help_files/image003.png"> <img width=169 height=212 id="Picture 6"
                                               src="assets/Images/Help_files/image004.png">
    </p>

    <h2 style='margin-left:.5in'>Main Tabs</h2>

    <h3 style='margin-left:1.0in'>Config</h3>

    <h3 style='margin-left:1.0in'>History</h3>

    <h3 style='margin-left:1.0in'>Promote</h3>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>
      <img width=751 height=275 id="Picture 5"
           src="assets/Images/Help_files/image005.png">
    </p>

    <p class=MsoNormal>
      By default, the application will be set on Config on
      startup.  You can select a line in the Job Listing to load the data for that
      tab.  If you have selected a job from the job listing, then when you select one
      of the three tabs, it will load that job’s information to that tab.
    </p>

    <h2 style='margin-left:.5in'>Configuration</h2>

    <p class=MsoNormal>
      Configuration consists of two parts, the Job definition and
      the schedule.  It has the following process buttons:
    </p>

    <h4>Job Definition</h4>

    <p class=MsoNormal>
      <img width=624 height=264 id="Picture 20"
           src="assets/Images/Help_files/image006.png"> 
    </p>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>
      <img width=624 height=231 id="Picture 21"
           src="assets/Images/Help_files/image007.png">
    </p>

    <p class=MsoNormal>
      <img width=526 height=266 id="Picture 23"
           src="assets/Images/Help_files/image008.png">
    </p>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>WCF Service:</p>

    <p class=MsoNormal>
      <img width=624 height=210 id="Picture 24"
           src="assets/Images/Help_files/image009.png">
    </p>

    <p class=MsoNormal>Rest Service:</p>

    <p class=MsoNormal>
      <img width=624 height=212 id="Picture 26"
           src="assets/Images/Help_files/image010.png">
    </p>

    <p class=MsoNormal>SQL Database:</p>

    <p class=MsoNormal>
      <img width=624 height=237 id="Picture 28"
           src="assets/Images/Help_files/image011.png">
    </p>

    <p class=MsoNormal>Power Shell:</p>

    <p class=MsoNormal>
      <img width=624 height=241 id="Picture 29"
           src="assets/Images/Help_files/image012.png">
    </p>

    <h4>Schedule</h4>

    <p class=MsoNormal>
      <img width=624 height=345 id="Picture 30"
           src="assets/Images/Help_files/image013.png">
    </p>

    <p class=MsoNormal>&nbsp;</p>

    <h2 style='margin-left:.5in'>History</h2>

    <p class=MsoNormal>
      <img width=624 height=255 id="Picture 14"
           src="assets/Images/Help_files/image014.png">
    </p>

    <p class=MsoNormal>
      If you clear the <b>Back</b> hours, then the following
      options will become available.
    </p>

    <p class=MsoNormal>Note: (m) = Minutes; (h)= Hours</p>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>
      <img width=624 height=133 id="Picture 11"
           src="assets/Images/Help_files/image015.png">
    </p>

    <p class=MsoNormal>
      You will get an invalid message if you attempt to select a
      date that is in the future.
    </p>

    <p class=MsoNormal>
      If you uncheck the <b>Now</b> checkbox you will get the
      following:
    </p>

    <p class=MsoNormal>
      <img width=624 height=156 id="Picture 12"
           src="assets/Images/Help_files/image016.png">
    </p>

    <p class=MsoNormal>
      If you attempt to set the <b>From</b> date after the <b>To</b>
      date, you will receive an invalid response for each date as seen below:
    </p>

    <p class=MsoNormal>
      <img width=624 height=72 id="Picture 13"
           src="assets/Images/Help_files/image017.png">
    </p>

    <h2 style='margin-left:.5in'>Promote</h2>

    <p class=MsoNormal>
      You will be asked to select the environment that you want to
      promote to:
    </p>

    <p class=MsoNormal>
      <img width=212 height=278 id="Picture 16"
           src="assets/Images/Help_files/image018.png">
    </p>

    <p class=MsoNormal>
      You will not have the above listing as Local will only be
      available in debug mode, and the current environment that you are on, will not
      be an option in the list.
    </p>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>
      <img width=767 height=166 id="Picture 10"
           src="assets/Images/Help_files/image019.png">
    </p>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>
      <img width=1254 height=219 id="Picture 8"
           src="assets/Images/Help_files/image020.png">
    </p>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>&nbsp;</p>

    <p class=MsoNormal>&nbsp;</p>

  </div>

</body>

</html>
