import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ButtonRendererComponent } from 'src/app/shared/services/button-renderer.component';
import { JobAlertsComponent } from '../job-alerts/job-alerts.component';
import { ModalDialogService } from 'src/app/modules/modaldialogbox/modal-dialog.service';
import * as moment from 'moment';

interface dropDwonval {
  value: string;
}

@Component({
  selector: 'app-job-configure',
  templateUrl: './job-configure.component.html',
  styleUrls: ['./job-configure.component.css']
})

export class JobConfigureComponent implements OnInit {

  constructor(
    private authorizationService: AuthorizationService,
    protected httpClient: HttpClient,
    private jobSummaryService: JobSummaryService,
    private jobAlertsComponent: JobAlertsComponent,
    public modalDialogService: ModalDialogService,
    private configService: ConfigService,
  ) {

    this.columnDefs = [
      { headerName: 'Key', field: 'key', editable: true, flex: 1 },
      { headerName: 'Value', field: 'value', editable: true, flex: 1 },
      {
        width: 65,
        flex: 0,
        headerName: "",
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.deleteRecord.bind(this)
        },
        cellStyle: { textAlign: 'right' },
      }
    ];
    this.defaultColDef = {
      resizable: false,
      height: 24,
    };
    this.rowSelection = 'single';
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent
    };
    this.gridOptions = {
      suppressHorizontalScroll: true,
      alwaysShowVerticalScroll: true,
      singleClickEdit: true,
      editType: 'fullRow',
      stopEditingWhenGridLosesFocus: true,
      //suppressClickEdit: true,
    };

  }
  //Security
  canEditJob: boolean = this.authorizationService.Authorized('editjob');
  canRunJob: boolean = this.authorizationService.Authorized('runjob');
  canAddJob: boolean = this.authorizationService.Authorized('addjob');
  canDeleteJob: boolean = this.authorizationService.Authorized('deletejob');

  //Control
  hasOwners: boolean = true;
  owners: any;

  headerName: string = "Configuration";
  myTextLimit: number = 500;
  wcfFields: boolean = false;
  restFields: boolean = false;
  sqlDatabaseFields: boolean = false;
  powerShellFields: boolean = false;
  regexPattern = '^[0-9]{5}(?:-[0-9]{4})?$';
  dropdownTypeVal: dropDwonval[] = [];
  //tableHeader = ['Key', 'Value', 'Description'];
  tableHeader = ['Key', 'Value', ''];
  frameworkComponents: any;
  public gridApi: any;
  public gridColumnApi: any;

  public columnDefs: any;
  public defaultColDef: any;
  public rowSelection: any;
  public gridOptions: any;

  public rowData: any = [];
  public historyRowData: any = [];
  jobID: any;
  public initialFormdata: any;
  jobForm = new UntypedFormGroup({
    Owner: new UntypedFormControl(''),
    System: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    Title: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    Description: new UntypedFormControl(''),
    Compare: new UntypedFormControl(''),
    TimeOut: new UntypedFormControl('', [Validators.pattern("^[0-9]*$")]),
    Type: new UntypedFormControl(''),
    checkbox: new UntypedFormControl(),
    URL: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    Contract: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    Method: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    RestURL: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    Body: new UntypedFormControl(''),
    Verb: new UntypedFormControl(),
    Hearders: new UntypedFormControl(),
    Server: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    Database: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|{\'})-_=+]{1,}')]),
    Command: new UntypedFormControl(''),
    CmdType: new UntypedFormControl(''),
    PowerCommand: new UntypedFormControl('', [Validators.pattern('[A-Za-z0-9_ !@#$%^&*("`~|\n{\'}<>)-_=+]{1,}')]),
  });

  @ViewChild('descriptionCtrl', { static: true }) descriptionCtrl: any;
  @ViewChild('compareCtrl', { static: true }) compareCtrl: any;
  @ViewChild('psCommandCtrl', { static: true }) psCommandCtrl: any;
  @ViewChild('sqlCommandCtrl', { static: true }) sqlCommandCtrl: any;
  @ViewChild('restBodyCtrl', { static: true }) restBodyCtrl: any;

  @ViewChild('psView', { static: true }) psView: any;
  @ViewChild('sqlView', { static: true }) sqlView: any;
  @ViewChild('restView', { static: true }) restView: any;

  onResize(event: any) {
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.descriptionCtrl.nativeElement.style.width = window.innerWidth / 4 - 35 + "px";
    this.compareCtrl.nativeElement.style.width = window.innerWidth / 4 - 35 + "px";
    this.psCommandCtrl.nativeElement.style.width = window.innerWidth / 2 - 140 + "px";
    this.sqlCommandCtrl.nativeElement.style.width = window.innerWidth / 2 - 140 + "px";
    this.restBodyCtrl.nativeElement.style.width = window.innerWidth / 2 - 320 + "px";
  }

  changeEvent(data: any) {
    if (data.value == "WCF Service (svc)" || data.value == "WCF Service (svc)") {
      this.wcfFields = true;
      this.restFields = false;
      this.sqlDatabaseFields = false;
      this.powerShellFields = false;
      //WLA Doing this because the *ngIf causes the textarea to not bind to the psCommandCtrl for resizing
      this.psView.nativeElement.style.display = 'none';
      this.sqlView.nativeElement.style.display = 'none';
      this.restView.nativeElement.style.display = 'none';

    }
    if (data.value == "REST Service") {
      this.wcfFields = false;
      this.restFields = true;
      this.sqlDatabaseFields = false;
      this.powerShellFields = false;
      this.psView.nativeElement.style.display = 'none';
      this.sqlView.nativeElement.style.display = 'none';
      this.restView.nativeElement.style.display = 'block';
    }
    if (data.value == "SQL Database Command") {
      this.wcfFields = false;
      this.restFields = false;
      this.sqlDatabaseFields = true;
      this.powerShellFields = false;
      this.psView.nativeElement.style.display = 'none';
      this.sqlView.nativeElement.style.display = 'block';
      this.restView.nativeElement.style.display = 'none';

    }
    if (data.value == "Power Shell") {
      this.wcfFields = false;
      this.restFields = false;
      this.sqlDatabaseFields = false;
      this.powerShellFields = true;
      this.psView.nativeElement.style.display = 'block';
      this.sqlView.nativeElement.style.display = 'none';
      this.restView.nativeElement.style.display = 'none';
      this.jobForm.controls["CmdType"].setValue(this.cmdType[0]);
    }
    if (data.value == "" || data.value == "Select") {
      this.wcfFields = false;
      this.restFields = false;
      this.sqlDatabaseFields = false;
      this.powerShellFields = false;
      this.psView.nativeElement.style.display = 'none';
      this.sqlView.nativeElement.style.display = 'none';
      this.restView.nativeElement.style.display = 'none';
    }
  }

  verbChangeEvent(event: any) {
    console.log(event);
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  deleteRecord(data: any) {
    this.rowData.splice(this.rowData.findIndex((x: any) => x.key == data.rowData.key), 1)
    this.gridApi.setRowData(this.rowData);
  }

  clickHeaderAdd() {
    //TODO:  Set the updateDate
    this.rowData.push({ key: '', value: '', jobRestHeaderId: 0, updateDate: '2021-04-04T07:13:03.548Z' });
    this.gridApi.setRowData(this.rowData);
    this.gridApi.setFocusedCell(this.rowData.length - 1, 'Key');
  }

  types = [
    { Sno: 0, label: 'WCF Service', value: 'WCF Service (svc)' },
    { Sno: 1, label: 'Rest Service', value: 'REST Service' },
    { Sno: 2, label: 'SQL Database', value: 'SQL Database Command' },
    { Sno: 3, label: 'Power Shell', value: 'Power Shell' },
  ];
  verbs = [
    { Sno: 0, label: 'GET', value: 'GET' },
    { Sno: 1, label: 'POST', value: 'POST', isSelected: true },
    { Sno: 2, label: 'PUT', value: 'PUT' },
    { Sno: 3, label: 'DELETE', value: 'DELETE' },
  ];
  cmdType = [
    { Sno: 0, label: 'Command', value: 'Command', isSelected: true },
    { Sno: 1, label: 'File', value: 'File' },
  ];

  ngOnDestroy() {
    this.jobSummaryService.jobConfigFormActive = false;
  }

  jobListSelectedFirstTime = true;
  configDirtyFirstTime = true;
  configClearDataFirstTime = true;

  ngOnInit(): void {
    this.jobSummaryService.jobConfigFormActive = true;

    this.jobSummaryService.jobListSelected$.subscribe((x: any) => {
      if (this.jobListSelectedFirstTime == true)
        this.jobListSelectedFirstTime = false;
      else
        this.getRecord();
    });

    this.jobSummaryService.configDirty$.subscribe((x: any) => {
      if (this.configDirtyFirstTime == true)
        this.configDirtyFirstTime = false;
      else
        this.checkDirty();
    });

    this.jobSummaryService.configClearData$.subscribe((x: any) => {
      if (this.configClearDataFirstTime == true)
        this.configClearDataFirstTime = false;
      else
        this.clearData();
    });
    this.getRecord();
  }

  //async getRecord() {
  //  await new Promise(() => {

  getRecord() {
    // if (this.jobSummaryService.jobConfigFormActive == false) return;

    //Set the owner drop down list
    this.owners = [];
    for (let i = 0; i < this.authorizationService.owners.length; i++) {
      var item = { label: this.authorizationService.owners[i], value: this.authorizationService.owners[i] };
      this.owners.push(item);
    }
    if (this.authorizationService.owners.length == 1) this.hasOwners = false;

    if (this.jobSummaryService.selectedRowId == null) {
      //Set defaults
      this.jobForm.controls["TimeOut"].setValue('0');

    } else {
      console.log("Getting Job " + this.jobSummaryService.selectedRowId);

      this.jobForm.addControl('jobId', new UntypedFormControl(''));
      var res = this.jobSummaryService.selectedRowData;
      this.jobForm.controls["System"].setValue(res.systemName);
      this.jobForm.controls["Title"].setValue(res.title);
      this.jobForm.controls["Description"].setValue(res.description);
      this.jobForm.controls["Compare"].setValue(res.compare);
      this.types.forEach(x => {
        if (x.value == res.jobType) {
          this.jobForm.controls["Type"].setValue(x);
          this.changeEvent(x);
        }
      });
      this.jobForm.controls["TimeOut"].setValue(res.timeoutMinutes == null || res.timeoutMinutes == '' ? '0' : res.timeoutMinutes);
      this.jobForm.controls["checkbox"].setValue(res.allowOverLap);

      this.jobID = res.jobId;
      this.jobForm.controls["jobId"].setValue(res.jobId);

      //SQL Database
      if (res.jobDb != null) {
        this.jobForm.controls["Server"].setValue(res.jobDb.serverName);
        this.jobForm.controls["Database"].setValue(res.jobDb.databaseName);
        this.jobForm.controls["Command"].setValue(res.jobDb.commandText);
      }

      //Rest Service
      if (res.jobRestService != null) {
        this.jobForm.controls["RestURL"].setValue(res.jobRestService.uri);
        this.jobForm.controls["Body"].setValue(res.jobRestService.uploadData);
        this.verbs.forEach(x => {
          if (x.value == res.jobRestService.requestMethod) {
            this.jobForm.controls["Verb"].setValue(x);
          }
        });
        this.rowData = res.jobRestService.jobRestHeaders;
      }

      //WCF Service
      if (res.jobService != null) {
        this.jobForm.controls["URL"].setValue(res.jobService.uri);
        this.jobForm.controls["Method"].setValue(res.jobService.methodName);
        this.jobForm.controls["Contract"].setValue(res.jobService.serviceContract);
      }

      //Power Shell
      if (res.jobPs != null) {
        this.cmdType.forEach(x => {
          if (x.value == res.jobPs.cmdType) {
            this.jobForm.controls["CmdType"].setValue(x);
          }
        });
        this.jobForm.controls["PowerCommand"].setValue(res.jobPs.commandText);
      }

      //Set the owner
      this.owners.forEach((x: any) => {
        if (x.value == res.owner) {
          setTimeout(() => {
            this.jobForm.controls["Owner"].setValue(x);
            this.jobForm.controls["Owner"].markAsPristine();
          }, 0);
        }
      });


      // Taking the copy of initial form, to check the dirty on Verb control.
      if (this.jobForm.controls["Type"].value?.value == 'REST Service') {
        this.initialFormdata = JSON.stringify(this.jobSummaryService.selectedRowData.jobRestService.jobRestHeaders);
      } else {
        this.initialFormdata = JSON.stringify([]);
      }
      this.jobSummaryService.alertClearData$.next();
      // this.jobAlertsComponent.loadGrid();

    }

    //Need to set all the data to prestine
    Object.keys(this.jobForm.controls).forEach(key => {
      this.jobForm.controls[key].markAsPristine();
    });
    console.log("owners");
    setTimeout(() => {
      this.jobSummaryService.selectedOwnerID=this.jobForm.controls["Owner"].value.value;
      console.log(this.jobForm.controls["Owner"].value.value);
    }, 1000);
    // console.log(this.jobForm.controls["Owner"].value.value);
    console.log(this.jobForm);
    //});
  }

  Delete() {
    console.log(this.jobID);
    console.log(this.jobSummaryService.selectedRowData);
    if (this.jobSummaryService.selectedRowData != undefined) {
      this.modalDialogService.confirm('Warning', 'Are you sure, you want to delete this record?').then((state: any) => {
        if (state) {
          this.httpClient.delete(this.configService.apiUrl + '/Scheduler/DeleteJob?jobId=' + this.jobSummaryService.selectedRowData.jobId).subscribe(data => {
            this.clearForNewJob();
            this.jobSummaryService.jobListRefresh$.next();

            this.jobSummaryService.deletePromoteList$.next(this.jobID);
            this.jobSummaryService.deletePromoteList = true;
          }, (error: any) => {
            //404 is sent when there is no data
            if (error.status != 404) {
              console.log(error);
              alert('An error occurred while attempting to delete job.  Please try again.  Error: ' + error.message);
            }
          });
        }
      });
    }
  }

  NewJob() {
    //Check for dirty data
    this.jobSummaryService.scheduleDirty$.next();
    this.jobSummaryService.configDirty$.next();
   // this.jobSummaryService.alertDirty$.next();
    this.rowData = [];

    if (this.jobSummaryService.scheduleDirty || this.jobSummaryService.configDirty||this.jobSummaryService.alertDirty$) {
      this.modalDialogService.confirm('Dirty Data on the form', 'Do you want to discard the changes?').then((state: any) => {
        if (state == true) this.clearForNewJob();
      });
    } else {
      this.clearForNewJob();
    }
  }

  clearForNewJob() {
    //Clear the selection on the Job-List
    this.jobSummaryService.jobListClear$.next();
    this.clearData();
    this.jobForm.controls["TimeOut"].setValue('0');
    this.jobForm.controls["TimeOut"].markAsPristine();
    this.changeEvent({ value: "" });
  }

  clearData() {
    //Clear the selection on the Job-List
    this.jobSummaryService.scheduleClearData$.next();

    this.jobSummaryService.selectedRowId = null;
    this.jobSummaryService.selectedRowData = null;
    this.rowData = [];

    //Clear Job-Config
    Object.keys(this.jobForm.controls).forEach(key => {
      this.jobForm.controls[key].setValue(null);
      this.jobForm.controls[key].markAsPristine()
    });
   // this.jobSummaryService.alertClearData$.next();
  }

  RunNow() {
    this.httpClient.get(this.configService.apiUrl + '/Scheduler/RunNow?jobId=' + this.jobID).subscribe(data => {
      this.historyRowData = data;
      alert('Your job has been scheduled to run immediately.');
      //Go to the History Tab
      this.jobSummaryService.homeSetHistoryView$.next();
    }, (error: any) => {
      //404 is sent when there is no data
      if (error.status != 404) {
        console.log(error);
        alert('An error occurred while attempting to run job.  Please try again.  Error: ' + error.message);
      }
    });
  }

  isConfigFormDirty: boolean = false;
  checkDirty() {
    this.isConfigFormDirty = false;

    if (this.jobForm.controls["System"].dirty == true) this.isConfigFormDirty = true;
    if (this.jobForm.controls["Title"].dirty == true) this.isConfigFormDirty = true;
    if (this.jobForm.controls["Description"].dirty == true) this.isConfigFormDirty = true;
    if (this.jobForm.controls["Compare"].dirty == true) this.isConfigFormDirty = true;
    if (this.jobForm.controls["TimeOut"].dirty == true) this.isConfigFormDirty = true;
    if (this.jobForm.controls["Type"].dirty == true) this.isConfigFormDirty = true;
    if (this.jobForm.controls["Owner"].dirty == true) this.isConfigFormDirty = true;

    if (this.jobForm.controls["Type"].value?.value == "WCF Service (svc)") {
      if (this.jobForm.controls["URL"].dirty == true) this.isConfigFormDirty = true;
      if (this.jobForm.controls["Contract"].dirty == true) this.isConfigFormDirty = true;
      if (this.jobForm.controls["Method"].dirty == true) this.isConfigFormDirty = true;
    }

    // Todo for verb
    if (this.jobForm.controls["Type"].value?.value == "REST Service") {
      if (this.jobForm.controls["RestURL"].dirty == true) this.isConfigFormDirty = true;
      if (this.jobForm.controls["Body"].dirty == true) this.isConfigFormDirty = true;
      if (this.jobForm.controls["Verb"].dirty == true) this.isConfigFormDirty = true;
      if (this.initialFormdata != JSON.stringify(this.rowData))
        this.isConfigFormDirty = true;
    }

    if (this.jobForm.controls["Type"].value?.value == "SQL Database Command") {
      if (this.jobForm.controls["Server"].dirty == true) this.isConfigFormDirty = true;
      if (this.jobForm.controls["Database"].dirty == true) this.isConfigFormDirty = true;
      if (this.jobForm.controls["Command"].dirty == true) this.isConfigFormDirty = true;
    }

    if (this.jobForm.controls["Type"].value?.value == "Power Shell") {
      if (this.jobForm.controls["PowerCommand"].dirty == true) this.isConfigFormDirty = true;
      if (this.jobForm.controls["CmdType"].dirty == true) this.isConfigFormDirty = true;
    }
    this.jobSummaryService.configDirty = this.isConfigFormDirty;
  }

  validRecords: boolean = true;
  async validateConfigRecords() {
    await new Promise(() => {

      //Clear requireds before validate
      Object.keys(this.jobForm.controls).forEach(key => {
        this.jobForm.controls[key].setErrors(null);
      });

      this.validRecords = true;

      if (this.jobForm.controls["System"].value == null || this.jobForm.controls["System"].value == '') {
        this.jobForm.controls["System"].setErrors({ 'required': 'true' });
        this.validRecords = false;
      }
      if (this.jobForm.controls["System"].valid == false) this.validRecords = false;


      if (this.jobForm.controls["Title"].value == null || this.jobForm.controls["Title"].value == '') {
        this.jobForm.controls["Title"].setErrors({ 'required': 'true' });
        this.validRecords = false;
      }
      if (this.jobForm.controls["Title"].valid == false) this.validRecords = false;

      if (this.jobForm.controls["TimeOut"].value == null || this.jobForm.controls["TimeOut"].value == '') {
        this.jobForm.controls["TimeOut"].setErrors({ 'required': 'true' });
        this.validRecords = false;
      }
      if (this.jobForm.controls["TimeOut"].valid == false) this.validRecords = false;

      if (this.jobForm.controls["Owner"].value?.value == 'Select ' || this.jobForm.controls["Owner"].value?.value == null) {
        if (this.authorizationService.owners.length == 1) {
          this.owners.forEach((x: any) => {
            if (x.value == this.authorizationService.owners[0]) {
              this.jobForm.controls["Owner"].setValue(x);
              this.jobForm.controls["Owner"].markAsPristine();
            }
          });
        } else {
          this.jobForm.controls["Owner"].setErrors({ 'required': 'true' });
          this.validRecords = false;
        }
      }

      if (this.jobForm.controls["Owner"].valid == false) this.validRecords = false;

      // For Type dropdown.
      this.jobForm.controls["Type"].setErrors(null);
      if (this.jobForm.controls["Type"].value == null || this.jobForm.controls["Type"].value == '' || this.jobForm.controls["Type"].value.label == 'Select') {
        this.jobForm.controls["Type"].setErrors({ 'required': 'true' });
        this.validRecords = false;
      } else { // check based on the Type selected.
        //"WCF Service (svc)
        if (this.jobForm.controls["Type"].value?.value == "WCF Service (svc)") {
          if (this.jobForm.controls["URL"].value == null || this.jobForm.controls["URL"].value == '') {
            this.jobForm.controls["URL"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["URL"].valid == false) this.validRecords = false;

          if (this.jobForm.controls["Contract"].value == null || this.jobForm.controls["Contract"].value == '') {
            this.jobForm.controls["Contract"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["Contract"].valid == false) this.validRecords = false;

          if (this.jobForm.controls["Method"].value == null || this.jobForm.controls["Method"].value == '') {
            this.jobForm.controls["Method"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["Method"].valid == false) this.validRecords = false;
        }

        //REST Service
        if (this.jobForm.controls["Type"].value?.value == "REST Service") {
          if (this.jobForm.controls["RestURL"].value == null || this.jobForm.controls["RestURL"].value == '') {
            this.jobForm.controls["RestURL"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["URL"].valid == false) this.validRecords = false;

          //WLA - Commented this out as body is not required
          //if (this.jobForm.controls["Body"].value == null || this.jobForm.controls["Body"].value == '') {
          //  this.jobForm.controls["Body"].setErrors({ 'required': 'true' });
          //  this.validRecords = false;
          //}
          if (this.jobForm.controls["Body"].valid == false) this.validRecords = false;
        }

        //SQL Database Command
        if (this.jobForm.controls["Type"].value?.value == "SQL Database Command") {
          if (this.jobForm.controls["Server"].value == null || this.jobForm.controls["Server"].value == '') {
            this.jobForm.controls["Server"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["Server"].valid == false) this.validRecords = false;

          if (this.jobForm.controls["Database"].value == null || this.jobForm.controls["Database"].value == '') {
            this.jobForm.controls["Database"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["Database"].valid == false) this.validRecords = false;

          if (this.jobForm.controls["Command"].value == null || this.jobForm.controls["Command"].value == '') {
            this.jobForm.controls["Command"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["Command"].valid == false) this.validRecords = false;
        }

        //Power Shell
        if (this.jobForm.controls["Type"].value?.value == "Power Shell") {

          if (this.jobForm.controls["CmdType"].value == null || this.jobForm.controls["CmdType"].value?.label == 'Select') {
            this.jobForm.controls["CmdType"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["PowerCommand"].value == null || this.jobForm.controls["PowerCommand"].value == '') {
            this.jobForm.controls["PowerCommand"].setErrors({ 'required': 'true' });
            this.validRecords = false;
          }
          if (this.jobForm.controls["PowerCommand"].valid == false) this.validRecords = false;
        }
      }

      //Others
      this.jobSummaryService.configValid = this.validRecords;

    });
  }

  jobObject: Object = new Object;
  jobRestService: Object = new Object;
  jobService: Object = new Object;
  jobDB: Object = new Object;
  jobPRequest: Object = new Object;
  histories = this.historyRowData;

  Save() {
    let currentDate = new Date();

    this.jobSummaryService.scheduleValidate$.next(null);
    this.validateConfigRecords();

    if (this.jobSummaryService.configValid == false || this.jobSummaryService.scheduleValid == false)
      return;

    this.jobPRequest = {
      cmdType: this.jobForm.controls["CmdType"].value?.value,
      commandText: this.jobForm.controls["PowerCommand"].value,
      jobPsId: this.jobSummaryService.selectedRowData?.jobPs == null ? 0 : this.jobSummaryService.selectedRowData?.jobPs?.jobPsId,
      updateDate: this.jobSummaryService.selectedRowData?.jobPs?.updateDate != null ? this.jobSummaryService.selectedRowData.jobPs.updateDate : currentDate
    }

    this.jobDB = {
      commandText: this.jobForm.controls["Command"].value,
      databaseName: this.jobForm.controls["Database"].value,
      jobDbId: this.jobSummaryService.selectedRowData?.jobDb?.jobDbId == null ? 0 : this.jobSummaryService.selectedRowData.jobDb.jobDbId,
      runInTransaction: true,//this.jobForm.controls["Server"].value,
      serverName: this.jobForm.controls["Server"].value,
      updateDate: this.jobSummaryService.selectedRowData?.jobDb?.updateDate != null ? this.jobSummaryService.selectedRowData.jobDb.updateDate : currentDate
    }
    //Clean up the headers that do not have Keys and Values
    for (var i = 0; i < this.rowData.length; i++) {
      if (this.rowData[i]["key"] == '' || this.rowData[i]["key"] == undefined ||
        this.rowData[i]["value"] == '' || this.rowData[i]["value"] == undefined) this.rowData.splice(i, 1);
    }

    this.jobRestService = {
      contentType: "",
      jobRestHeaders: this.rowData,
      jobRestServiceId: this.jobSummaryService.selectedRowData?.jobRestService?.jobRestServiceId == null ? 0 : this.jobSummaryService.selectedRowData.jobRestService.jobRestServiceId,
      requestMethod: this.jobForm.controls["Verb"].value?.value == null ? "" : this.jobForm.controls["Verb"].value.value,
      updateDate: this.jobSummaryService.selectedRowData?.jobRestService?.updateDate != null ? this.jobSummaryService.selectedRowData.jobRestService.updateDate : currentDate,
      uploadData: this.jobForm.controls["Body"].value == null ? "" : this.jobForm.controls["Body"].value,
      // uploadData: this.jobForm.controls["Body"].value,
      uri: this.jobForm.controls["RestURL"].value,
    }

    this.jobService = {
      jobServiceId: this.jobSummaryService.selectedRowData?.jobService?.jobServiceId == null ? 0 : this.jobSummaryService.selectedRowData.jobService.jobServiceId,
      methodName: this.jobForm.controls["Method"].value,
      serviceContract: this.jobForm.controls["Contract"].value,
      updateDate: this.jobSummaryService.selectedRowData?.jobService?.updateDate != null ? this.jobSummaryService.selectedRowData.jobService.updateDate : currentDate,
      uri: this.jobForm.controls["URL"].value,
    }

    this.jobObject = {
      allowOverLap: this.jobForm.controls["checkbox"].value == null ? false : this.jobForm.controls["checkbox"].value,
      compare: this.jobForm.controls["Compare"].value == null ? "" : this.jobForm.controls["Compare"].value,
      description: this.jobForm.controls["Description"].value == null ? "" : this.jobForm.controls["Description"].value,
      enabled: this.jobSummaryService.schduleData?.isEnabled == null ? false : this.jobSummaryService.schduleData?.isEnabled,
      jobId: this.jobSummaryService.selectedRowId == null ? 0 : this.jobSummaryService.selectedRowId,

      jobDb: this.jobForm.controls["Type"].value.label == 'SQL Database' ? this.jobDB : null,
      jobPs: this.jobForm.controls["Type"].value.label == 'Power Shell' ? this.jobPRequest : null,
      jobRestService: this.jobForm.controls["Type"].value.label == 'Rest Service' ? this.jobRestService : null,
      jobService: this.jobForm.controls["Type"].value.label == 'WCF Service' ? this.jobService : null,

      jobType: this.jobForm.controls["Type"].value.value,
      owner: this.jobForm.controls["Owner"].value.value,
      schedule: this.jobSummaryService.schduleData,
      status: this.jobForm.controls["Server"].value,
      systemName: this.jobForm.controls["System"].value,
      timeoutMinutes: this.jobForm.controls["TimeOut"].value,
      title: this.jobForm.controls["Title"].value,
      updateDate: this.jobSummaryService.selectedRowData?.updateDate != null ? this.jobSummaryService.selectedRowData.updateDate : currentDate

    }

    console.log("jobObject_Stringified: ")
    console.log(JSON.stringify(this.jobObject));
    console.log(" ");
    console.log("jobObject: ");
    console.log(this.jobObject);
    //console.log(" ");
    //console.log(" ");
    //console.log("jobForm: ");
    //console.log(this.jobForm.controls);
    //console.log(" ");
    //var pattern = /^\d+$/;
    //console.log(pattern.test(this.jobForm.controls["TimeOut"].value));

    this.httpClient.post(this.configService.apiUrl + '/Scheduler/SaveJob', this.jobObject).subscribe((data:any) => {
      console.log(data);

      alert('Record Saved!');

      //reload the job
      if (this.jobSummaryService.selectedRowId != null && this.jobSummaryService.selectedRowId != 0 && this.jobSummaryService.selectedRowId != undefined) {
        this.httpClient.post<any>(this.configService.apiUrl + '/Scheduler/GetJob?jobId=' + this.jobSummaryService.selectedRowId, { title: 'Job POST Request' }).toPromise().then((returnData: any) => {
          this.jobSummaryService.selectedRowData = returnData; // Object.assign({}, data);
          this.jobSummaryService.jobListSelected$.next();
        }, (error: any) => {
          //404 is sent when there is no data
          if (error.status != 404) {
            console.log(error);
            alert('An error occurred while attempting to reload the saved data.  Please try again.  Error: ' + error.message);
          }
        });
      } else {
        // this.clearForNewJob();
        // this.jobSummaryService.jobListRefresh$.next();
        // alert('Select your job from the Job Listing in order to continue editing.');

        this.jobSummaryService.jobListRefresh$.next();
        setTimeout(() => {
          this.jobSummaryService.reloadRecord = true;
          this.jobSummaryService.selectedRowId = data.jobId;
          this.jobSummaryService.reloadRecord$.next();           
        }, 1000); 
      }


    }, (error: any) => {
      console.log(error);
      let er = typeof error.error == "string" ? error.error : (error.error?.title == null ? "Not available" : error.error?.title);
      let msg = 'An error occurred while attempting to save the data.  \n\nSave Error:\n' + error.message + "\n\n" + "Error Details:\n" + er;
      alert(msg);
    });


  }

}
