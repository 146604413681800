

<div #formControl>
  <div class="uitk-l-grid" style="margin:0px;margin-top:2px;width:100%;">
    <div class="uitk-l-grid__row" style="margin:0px;padding:0px;">
      <div class="uitk-l-grid__col--1" style="margin:0px;padding:0px;min-width:105px;">
        <h5 style="margin:0px;padding:0px;">
          Job Listing
        </h5>
      </div>
      <div class="uitk-l-grid__col--4 uitk-u-flex__align--left" style="margin:0px;padding:0px;">
        <div style="position:relative;top:-1.5px;left:0px;">
          <button type="button" (click)="onRefreshGrid()" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs">
            <span class="uitk-icon uitk-icon-refresh sm-icon"></span> Refresh
          </button>
        </div>
      </div>
      <!--<div class="uitk-l-grid__col--4 uitk-u-flex__align--left" style="margin:0px;padding:0px;">
        <span style="font-size:8px;">(Double click to select)</span>
      </div>-->
      <div class="uitk-l-grid__col uitk-u-flex__align--right" style="margin:0px;padding:0px;">
        <input #filterControl type="text" id="filterText" (keyup)="quickSearch()" placeholder="Filter" [(ngModel)]="filter" style="width:100%;height:24px;position:relative;top:2px;left:10px;" />
      </div>
      <button type="button" class="btn btn-hover" (click)="ClearFilter()" style="z-index:100;position:relative;top:2px;left:-8px;">
        x
      </button>
      <!--<button type="button" (click)="ClearFilter()" class="uitk-c-button" style="z-index:100;position:relative;top:3px;right:1px;height:18px;padding:0px;margin:0px;">
        <span class="uitk-icon uitk-icon-close sm-icon"></span>
      </button>-->
    </div>
  </div>
  <div style="position:relative;top:-4px;">
    <hr class="line1" />
    <div style="height:4px;"></div>
    <!-- <ag-grid-angular style="width: 100%; height:500px;" class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs" >
    </ag-grid-angular> -->
    <div #viewDiv (window:resize)="onResize($event)">
      <ag-grid-angular #agGrid
                       style="height:100%;"
                       id="jobListGrid"
                       class="ag-theme-alpine"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="defaultColDef"
                       [rowSelection]="rowSelection"
                       [rowData]="rowData"
                       (cellDoubleClicked)="onCellDoubleClicked($event)"
                       [rowClassRules]="rowClassRules"
                       [suppressRowClickSelection]="true"
                       (gridReady)="onGridReady($event)"
                       [enableCellTextSelection]="true"
                       [frameworkComponents]="frameworkComponents"></ag-grid-angular>
    </div>
  </div>
</div>
<!--(rowClicked)="onRowClicked($event)"-->
