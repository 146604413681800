import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
//import { Mode } from 'src/app/shared/enums/mode.enum';
import { JobSummaryDto } from 'src/app/shared/models/JobSummaryDto.model';
import { JobSummaryService } from 'src/app/shared/services/job-summary.service';
import { ModeService } from 'src/app/shared/services/mode.service';
import { HttpClient } from '@angular/common/http';
import { JobConfigureComponent } from 'src/app/modules/job-configure/job-configure.component';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ModalDialogService } from 'src/app/modules/modaldialogbox/modal-dialog.service';
import { ListButtonRendererComponent } from 'src/app/shared/services/list-button-renderer.component'
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { JobAlertsComponent } from 'src/app/modules/job-alerts/job-alerts.component';

interface systemData {
    system: string;
    title: string;
    jobId: string;
    status: string;
}

@Component({
    selector: 'app-job-list',
    templateUrl: './job-list.component.html',
    styleUrls: ['./job-list.component.css']
})

export class JobListComponent implements OnInit {

    public gridApi: any;
    public gridColumnApi: any;

    public columnDefs: any;
    public defaultColDef: any;
    public rowSelection: any;
    public rowData = [];

    frameworkComponents: any;

    //old
    allJobSummaries: JobSummaryDto[] = [];
    filteredJobSummaries: JobSummaryDto[] = [];
    filter = "";
    tableHeader = ['System', 'Title', 'Type', 'Enabled', 'Status'];
    // This is required to allow the Mode enum to be accessible by the component html page.
    //Mode = Mode;

    constructor(
        public jobConfigureComponent: JobConfigureComponent,
        public jobAlertsComponent: JobAlertsComponent,
        public modeService: ModeService,
        protected httpClient: HttpClient,
        private configService: ConfigService,
        public modalDialogService: ModalDialogService,
        private jobSummaryService: JobSummaryService,
        private authorizationService: AuthorizationService,
    ) {
        this.columnDefs = [
            { headerName: 'System', field: 'systemName', minWidth: 0, sortable: true, filter: true },
            { headerName: 'Title', field: 'title', minWidth: 0, sortable: true, filter: true },
            { headerName: 'JobType', field: 'jobType', minWidth: 0, sortable: true, filter: true },
            {
                headerName: 'Enabled', field: 'enabled', minWidth: 0, sortable: true, filter: true,
                flex: 0,
                maxWidth: 105,
                cellStyle: function (params: any) {
                    if (params.value == true) {
                        return { color: '#26b539' };
                    } else {
                        return { color: 'red' };
                    }
                },
            },
            {
                headerName: 'Status', field: 'status', minWidth: 0, sortable: true, filter: true,
                flex: 0,
                maxWidth: 95,
                cellStyle: function (params: any) {
                    if (params.value == 'Success') {
                        return { backgroundColor: 'lightgreen' };
                    } else if (params.value == 'Never Run') {
                        return { backgroundColor: 'white' };
                    } else if (params.value == 'InProcess') {
                        return { backgroundColor: 'lightyellow' };
                    }
                    else if (params.value == 'SystemFailure') {
                        return { backgroundColor: 'red' };
                    }
                    else {
                        return { backgroundColor: 'salmon' };
                    }
                }
            },
            {
                maxWidth: 65,
                flex: 0,
                headerName: "",
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                    onClick: this.onCellSelect.bind(this)
                },
                cellStyle: {
                    textAlign: 'right',
                },
            }
        ];
        this.defaultColDef = {
            flex: 1,
            resizable: true,
            cellStyle: {
                padding: 0
            }
        };
        this.rowSelection = 'single';
        this.frameworkComponents = {
            buttonRenderer: ListButtonRendererComponent
        };
    }

    rowClassRules: any = {
        'row-hightligt': 'data.clicked'
    }

    onResize(event: any) {
        this.ngAfterViewInit();
    }

    @ViewChild('viewDiv', { static: true }) viewDiv: any;
    @ViewChild('filterControl', { static: true }) filterControl: any;
    @ViewChild('formControl', { static: true }) formControl: any;

    ngAfterViewInit() {
        this.viewDiv.nativeElement.style.height = window.innerHeight - 125 + "px";
        this.formControl.nativeElement.style.height = window.innerHeight - 125 + "px";
    }

    systemName: systemData[] = [];
    //on grid
    onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.loadGrid();
    }

    loadGrid() {
        this.httpClient.get(this.configService.apiUrl + '/Scheduler/GetJobSummaries').subscribe((data: any) => {
            var myData: any = [];
            for (var i = 0; i < data.length; i++) {
                if (this.authorizationService.owners.indexOf(data[i]["owner"]) > -1)
                    myData.push(data[i]);
            }
            this.rowData = myData;

            this.systemName = [];
            this.rowData.forEach((x: any) => {
                if (x.systemName != null && (this.systemName.indexOf(x.systemName) == -1))
                    this.systemName.push({ system: x.systemName, title: x.title, jobId: x.jobId, status: '' });
            });
            this.jobSummaryService.promoteSystemNameList$.next(this.systemName);
        }, (error: any) => {
            //404 is sent when there is no data
            if (error.status != 404) {
                console.log(error);
                alert('An error occurred while getting job listing.  Please try again.  Error: ' + error.message);
            }
        });
    }

    jobListClearFirstTime = true;
    jobListRefreshFirstIime = true;
    ngOnInit() {
        this.jobSummaryService.jobListClear$.subscribe((x: any) => {
            if (this.jobListClearFirstTime == true)
                this.jobListClearFirstTime = false;
            else
                this.clearSelected();
        });
        this.jobSummaryService.jobListRefresh$.subscribe((x: any) => {
            if (this.jobListRefreshFirstIime == true)
                this.jobListRefreshFirstIime = false;
            else
                this.loadGrid();
        });

        this.jobSummaryService.reloadRecord$.subscribe((x: any) => {
            if (this.jobSummaryService.reloadRecord == true) {
                this.gridApi.rowModel.rowsToDisplay.forEach((element: any) => {
                    if (element.data.jobId == this.jobSummaryService.selectedRowId) {
                        this.onCellSelect(element);
                    }
                });
            }
        });
    }
    quickSearch() {
        this.gridApi.setQuickFilter(this.filter);
    }

    clearSelected() {
        if (this.gridApi != undefined) {
            let selected = this.gridApi.getSelectedNodes();
            if (selected.length > 0) {
                selected[0].setSelected(false);
                this.jobSummaryService.configClearData$.next();
            }
        }
    }

    onCellSelect(item: any) {
        if (this.jobSummaryService.reloadRecord == false) {
            let rowData = this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex);
            let node = { 'node': rowData, 'data': { 'jobId': rowData.data.jobId } };
            this.jobSummaryService.selectedOwnerID = rowData.data.owner;
            if (node != undefined) this.onCellDoubleClicked(node);
        }
        else {
            let rowData = this.gridApi.getDisplayedRowAtIndex(item.rowIndex);
            let node = { 'node': rowData, 'data': { 'jobId': rowData.data.jobId } };
            this.jobSummaryService.selectedOwnerID = rowData.data.owner;
            if (node != undefined) this.onCellDoubleClicked(node);
        }
    }

    async onCellDoubleClicked(data: any) {
        let getData = false;
        let selected = this.gridApi.getSelectedNodes();
        if (selected.length == 0) {
            if (this.jobSummaryService.reloadRecord == false) {
                this.jobSummaryService.scheduleDirty$.next();
                this.jobSummaryService.configDirty$.next();
                if (this.jobSummaryService.scheduleDirty || this.jobSummaryService.configDirty) {
                    await this.modalDialogService.confirm('Dirty Data on the form', 'Do you want to discard the changes?').then((state: any) => {
                        if (state == false) {
                            return;
                        } else {
                            data.node.setSelected(true, true);
                            this.jobSummaryService.selectedRowId = data.data.jobId;
                            getData = true;
                        }
                    });
                }
                else {
                    data.node.setSelected(true, true);
                    this.jobSummaryService.selectedRowId = data.data.jobId;
                    getData = true;
                }
            }
            else {
                data.node.setSelected(true, true);
                this.jobSummaryService.selectedRowId = data.data.jobId;
                getData = true;
                this.jobSummaryService.reloadRecord = false;
            }
        } else {

            if (this.jobSummaryService.jobConfigFormActive) {
                this.jobSummaryService.scheduleDirty$.next();
                this.jobSummaryService.configDirty$.next();
                console.log('Schedule Dirty: ' + this.jobSummaryService.scheduleDirty);

                if (this.jobSummaryService.scheduleDirty || this.jobSummaryService.configDirty || this.jobSummaryService.alertDirty) {
                    await this.modalDialogService.confirm('Dirty Data on the form', 'Do you want to discard the changes?').then((state: any) => {
                        if (state == false) {
                            return;
                        } else {
                            data.node.setSelected(true, true);
                            this.jobSummaryService.selectedRowId = data.data.jobId;
                            getData = true;
                        }
                    });
                } else {
                    data.node.setSelected(true, true);
                    this.jobSummaryService.selectedRowId = data.data.jobId;
                    getData = true;
                }
            } else {
                data.node.setSelected(true, true);
                this.jobSummaryService.selectedRowId = data.data.jobId;
                getData = true;
            }
        }

        // Get the data 
        if (getData) {
            await this.httpClient.post<any>(this.configService.apiUrl + '/Scheduler/GetJob?jobId=' + this.jobSummaryService.selectedRowId, { title: 'Job POST Request' }).toPromise().then((returnData: any) => {
                this.jobSummaryService.selectedRowData = returnData;
            }, (error: any) => {
                //404 is sent when there is no data
                if (error.status != 404) {
                    console.log(error);
                    alert('An error occurred while getting job data.  Please try again.  Error: ' + error.message);
                }
            });
            this.jobSummaryService.jobListSelected$.next();
        }
    }

    ngOnDestroy() {
    }


    onChangeFilter() {
        this.filteredJobSummaries = this.allJobSummaries.filter(j =>
            j.systemName.toUpperCase().indexOf(this.filter.toUpperCase()) > -1
            || j.title.toUpperCase().indexOf(this.filter.toUpperCase()) > -1);
    }

    ClearFilter() {
        this.filter = '';
        this.quickSearch();
        this.filterControl.nativeElement.focus();
    }

    onRefreshGrid() {
        if (this.jobSummaryService.selectedRowId != null) {

            //Check for dirty data
            this.jobSummaryService.scheduleDirty$.next();
            this.jobSummaryService.configDirty$.next();
            this.jobSummaryService.alertDirty$.next();
            if (this.jobSummaryService.scheduleDirty || this.jobSummaryService.configDirty || this.jobSummaryService.alertDirty) {
                this.modalDialogService.confirm('Dirty Data on the form', 'Do you want to discard the changes?').then((state: any) => {
                    if (state == true) {
                        this.performRefresh();
                    }
                });
            } else {
                this.performRefresh();
            }
        } else {
            this.performRefresh()
        }
    }

    performRefresh() {
        this.clearSelected();
        this.loadGrid();
        this.jobSummaryService.isEnableDeleteButton$.next(true);
        this.jobSummaryService.alertClearData$.next();

        // this.jobAlertsComponent.loadGrid();
        this.jobSummaryService.configClearData$.next();
    }
}
