<div #formControl>
<div style="position:relative;top:-1.5px;left:365px;width:320px;z-index:1000;">
  <button  type="button" [disabled]="checkButton()" (click)="addRow()" id="button1" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" style="min-height:0px; font-size: 12px;padding: 2px;">
    <span class="uitk-icon uitk-icon-add sm-icon"></span> Add</button>
    <button  type="button" [disabled]="(disableButtons$ |async) || disableDelete" (click)="delete()" id="button1" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" style="min-height:0px; font-size: 12px;padding: 2px;">
      <span class="uitk-icon uitk-icon-trash_delete sm-icon"></span> Delete</button>
    <button type="button"  (click)="save()" id="button1" class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs" style="
  min-height: 0px;font-size: 12px;padding: 2px;">
      <span class="uitk-icon uitk-icon-save sm-icon"></span> Save
    </button>
</div>

  <div  style="position:relative;top:-30px;">
    <h5 style="margin:0px;padding:0px;">
      Alerts
    </h5>
    <hr class="line1" />
</div>
<div class="uitk-l-grid__row" style="margin:0px;padding:0px;position:relative;top:-24px;">
  <div class="uitk-l-grid__col" style="margin:0px;padding:0px;">
      <div #viewDiv (window:resize)="onResize($event)" style="min-width:664px;">
      <ag-grid-angular #agGrid
                       style="width:100%;height:100%"
                       id="jobListGrid"
                       class="ag-theme-alpine"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="defaultColDef"
                       [rowSelection]="rowSelection"
                       (rowClicked)="onRowClicked($event)"
                       [getRowNodeId]="getRowNodeId"
                       [gridOptions]="gridOptions"
                       (gridReady)="onGridReady($event)">
                      </ag-grid-angular>
    </div>
  </div>
</div>
</div>
